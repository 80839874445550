<template>
	<div id="body">
		<h1>Browserflows compiler v<span id="version">0.4.3</span> (develop)</h1>
		<div id="code-div">
			<div id="editor-div">
				<pre id="line-numbers"></pre>
				<pre id="source-code" contenteditable="true" spellcheck="false">{{src}}</pre>
			</div>
			<div id="output-and-errors-div" tabindex="-1">
				<p><b>Statistics</b></p>
				<table><tbody id="statistics"></tbody></table>
				<pre id="output-json"></pre>
				<p><b>Errors</b></p>
				<div id="errors-div">
					<table>
						<thead></thead>
						<tbody></tbody>
					</table>
				</div>
			</div>
			<div id="right-menu">
				<button id="publish">Publish</button>
				<button id="changelog" popovertarget="changelog-popover" popoveraction="toggle">Changelog</button>
				<button id="load">Load</button>
				<div id="changelog-popover" popover="auto">
					<h2>Changelog</h2>
					<h3>Current</h3>
					<ul></ul>
					<h3>v0.4.2</h3>
					<ul>
						<li>Functions can now receive multiple parameters</li>
						<li>Added import system: import 'what' as identifier;</li>
						<li>Added break and continue for while/do/end and for/in/do/end</li>
						<li>Now it is possible to create functions: function identifier($variable1; $variableN) end;</li>
						<li>Autocomplete is now smarter, with filtering and sorting based on full string instead of beginning</li>
						<li>Added a little animation to publish button</li>
						<li>Added basic try/catch/do/finally/end (variable structure: { stepIndex, nextStepIndex, error, errorMessage })</li>
						<li>WARNING: Return from a while/do/end, foreach/in/do/end and try/catch/do/finally/end is not supported yet</li>
						<li>Brackets can now automatically group complex expressions (note: the ternary operator groups from the end)</li>
						<li>Macro_Breakpoint can now be used to inspect variables and first-level accessors</li>
						<li>
							<p>Added functions</p>
							<ul>
								<li>_System_showWindow([$title : string], $message: string)</li>
							</ul>
						</li>
						<li>
							<p>Added macros</p>
							<ul>
								<li>Macro_AddRequiredInput_Date *variable* [title: *title*] [subtitle: *subtitle*] [range: 0|1]</li>
								<li>Macro_Description</li>
							</ul>
						</li>
						<li>
							<p>Enhanced macros</p>
							<ul>
								<li>Macro_AddRequiredInput_ExcelIterator *variableExcel* [title: *title*] [hint: *hint*] [selectColumn: *variableColumn*] [columnTitle: *title*] [columnHint: *hint*] [outputVariable: *variable*] [outputFileName: *string*] [schema: *string-json-schema*]</li>
								<li>Macro_RawBlock *stepType* *action* *variable*... [timeout: *timeout*] [catchTimeout: *timeout*] [disableInteractionDetection: 0|1]</li>
								<li>Macro_Breakpoint [*value*]</li>
							</ul>
						</li>
					</ul>
					<h3>v0.4.1</h3>
					<ul>
						<li>Started function system</li>
						<li>Functions can receive one parameter</li>
						<li>Minimal variable and constant information</li>
						<li>
							<p>Added functions</p>
							<ul>
								<li>_System_sleep($ms : number)</li>
								<li>_System_showMessage($message : string)</li>
								<li>_MainBrowser_create() : string</li>
								<li>_MainBrowser_dispose() : string</li>
							</ul>
						</li>
					</ul>
					<h3>v0.4.0</h3>
					<ul>
						<li>Reworked generation system from full tree to cascading in order to advance types and functions</li>
					</ul>
					<h3>v0.3.2</h3>
					<ul>
						<li>Macro_AddRequiredInput_ExcelIterator [schema:] now does validation</li>
						<li>
							<p>Enhanced macros</p>
							<ul>
								<li>Macro_ShowWindow [*title*] *message* [catchTimeout: *timeout*] [disableInteractionDetection: 0|1]</li>
								<li>Macro_AddRequiredInput_ExcelIterator *variableExcel* [title: *title*] [hint: *hint*] [selectColumn: *variableColumn*] [columnTitle: *title*] [columnHint: *hint*] [schema: *string-json-schema*]</li>
							</ul>
						</li>
						<li>
							<p>Deprecated macros</p>
							<ul>
								<li>Macro_AddRequiredInput_ExcelIteratorWithFilenameColumn</li>
							</ul>
						</li>
					</ul>
					<h3>v0.3.1</h3>
					<ul>
						<li>Now publishing a flow with id "new" will request the backend to create a new flow, and update the URL accordingly</li>
						<li>Enhanced valid @flowId detection</li>
						<li>Added @instructionsURL *url*</li>
						<li>Added @templateURL *url*</li>
						<li>Added @timeSavedRatio *number*</li>
						<li>Added @usageRatio *number*</li>
						<li>Added @autoRepeatErrors *number*</li>
						<li>Added @outputDirectoryVariable *variable*</li>
						<li>Added @userLogFileVariableKey *variable*</li>
						<li>foreach/in/do/end can now do complex operations for "in" part</li>
						<li>
							<p>Added macros:</p>
							<ul>
								<li>Macro_Breakpoint</li>
								<li>Macro_MainBrowser_Create</li>
								<li>Macro_MainBrowser_Dispose</li>
								<li>Macro_MainBrowser_GetAttribute *variable* *xpath* *attribute* [catchTimeout: *timeout*]</li>
							</ul>
						</li>
						<li>
							<p>Enhanced macros:</p>
							<ul>
								<li>Macro_AddRequiredInput_ExcelIterator *variable* [title: *title*] [hint: *hint*] [autoIterate: 0|1] [schema: *string-json-schema*]</li>
								<li>Macro_AddRequiredInput_ExcelIteratorWithFilenameColumn *variableExcel* [title: *title*] [hint: *hint*] *variableColumn* [title2: *title2*] [hint2: *hint*] [autoIterate: 0|1] [schema: *string-json-schema*]</li>
								<li>Macro_ShowWindow [*title*] *message* [catchTimeout: *timeout*]</li>
							</ul>
						</li>
					</ul>
					<h3>v0.3.0</h3>
					<ul>
						<li>String concatenation: &lt;&gt;</li>
						<li>Removed prepared variables</li>
						<li>Temporary variables system added</li>
						<li>Brackets can now do complex operations</li>
						<li>if/then/else/end, while/do/end and accessors can now use brackets for complex expressions</li>
						<li>
							<p>Added macros:</p>
							<ul>
								<li>Macro_MainBrowser_GetInnerHTML *variable* *xpath* [catchTimeout: *timeout*]</li>
								<li>Macro_RawBlockJson_Input *list|object*</li>
								<li>Macro_RawBlockJson_Step *list|object*</li>
								<li>Macro_RawBlockJson_Variable *list|object*</li>
							</ul>
						</li>
						<li>
							<p>Enhanced macros:</p>
							<ul>
								<li>Macro_AddRequiredInput_ExcelIteratorWithFilenameColumn *variableExcel* [title: *title*] [hint: *hint*] *variableColumn* [title2: *title*] [hint2: *hint*] [schema: *string-json-schema*]</li>
							</ul>
						</li>
					</ul>
					<h3>v0.2.3</h3>
					<ul>
						<li>Multiplication, divission and modulus</li>
						<li>Added @user, @group and @public for flow accessControl</li>
						<li>Added @digitalCertificateRequired</li>
						<li>Added foreach/in/do/end</li>
						<li>Added line numbers</li>
						<li>
							<p>Added macros:</p>
							<ul>
								<li>Macro_AddInvisibleInput_LogStream *variable*</li>
								<li>Macro_AddInvisibleInput_StreamIterator *variable*</li>
								<li>Macro_AddInvisibleInput_UserLogStream *variable*</li>
								<li>Macro_AddRequiredInput_DownloadFolder *variable* [title: *title*] [hint: *hint*]</li>
								<li>Macro_AddRequiredInput_ExcelIterator *variable* [title: *title*] [hint: *hint*]</li>
								<li>Macro_AddRequiredInput_ExcelIteratorWithFilenameColumn *variableExcel* [title: *title*] [hint: *hint*] *variableColumn* [title2: *title*] [hint2: *hint*]</li>
								<li>Macro_AddRequiredInput_MultipleCertificates *variable* [autoIterate: 0|1]</li>
								<li>Macro_AddInput_SendEmailOption *variable* [title: *title*] [hint: *hint*]</li>
								<li>Macro_AddMemory *variable* *initialValue*</li>
								<li>Macro_MainBrowser_SaveAs *outputFileName* *baseFolderPath* *pdfEmbebElement* *outputFolderDefaultName* *filePath*</li>
								<li>Macro_Substring *variable* *source* *start* *length*</li>
								<li>Macro_Available *variable* *iterator*</li>
								<li>Macro_Read *variable* *iterator*</li>
								<li>Macro_Write *iterator* *variable*</li>
								<li>Macro_ReadMultiple *variable* *iterator* [*amount*]</li>
								<li>Macro_WriteMultiple *iterator* *list*</li>
								<li>Macro_AddRequiredInput_Legacy_Excel *variable*</li>
							</ul>
						</li>
						<li>
							<p>Enhanced macros:</p>
							<ul>
								<li>Macro_MainBrowser_PrimaryClick *xpath* [catchTimeout: *timeout*] [catchException: 0|1]</li>
							</ul>
						</li>
					</ul>
					<h3>v0.2.2</h3>
					<ul>
						<li>Fixed error when removing first character of source</li>
						<li>Added source module+line+column in description to trace source code</li>
						<li>Now composing keys (e.g. tilde) don't update the code</li>
						<li>
							<p>Added macros:</p>
							<ul>
								<li>Macro_TypeText *text*</li>
							</ul>
						</li>
						<li>
							<p>Enhanced macros:</p>
							<ul>
								<li>Macro_Sleep now generates timeout if needed</li>
							</ul>
						</li>
					</ul>
					<h3>v0.2.1</h3>
					<ul>
						<li>Attempt at solving unexpected cursor jumps when deleting text</li>
						<li>Added basic parameter information on changelog</li>
						<li>Added source code and compiler version to flow</li>
						<li>Backend now automatically fills the flow received on query parameter "flow"</li>
						<li>Reminder: change compiler version using query parameter "version" (default: "latest")</li>
						<li>
							<p>Added macros:</p>
							<ul>
								<li>Macro_Count *variable* *list*</li>
								<li>Macro_RawBlock *stepType* *action* *variable*...</li>
								<li>Macro_AddRequiredInput_SingleCertificate *variable*</li>
							</ul>
						</li>
					</ul>
					<h3>v0.2.0</h3>
					<ul>
						<li>Reworked parser</li>
						<li>Added if/then/else/end and while/do/end</li>
						<li>Added more macros</li>
						<li>Macro suggestions appear over text if below 50% window height</li>
						<li>
							<p>Added macros:</p>
							<ul>
								<li>Macro_EnumerateProcesses *variable*</li>
								<li>Macro_JavaAccessBridge_EnumerateJVMs *variable*</li>
								<li>Macro_JavaAccessBridge_EnumerateWindows *variable* *jvm*</li>
								<li>Macro_JavaAccessBridge_GetStatus *variable*</li>
								<li>Macro_JavaAccessBridge_SetStatus *status*</li>
								<li>Macro_SendVirtualKeyCodes *FlaUI.Core.WindowsAPI.VirtualKeyShort*...</li>
							</ul>
						</li>
						<li>
							<p>Enhanced macros</p>
							<ul>
								<li>Macro_ShowWindow [*title*] *message*</li>
							</ul>
						</li>
					</ul>
					<h3>v0.1.0</h3>
					<ul>
						<li>Initial code highlighting</li>
						<li>Variable assignments can use expressions with 1 or 3 elements</li>
						<li>Flow variables come from source variables</li>
						<li>Comments and configuration (multiline comment just at the start of the file)</li>
						<li>Flow calls are implemented as macros since expressions are not done yet</li>
						<li>Variables, additions, substractions and template string</li>
						<li>
							<p>Added macros:</p>
							<ul>
								<li>Initial macro suggestions</li>
								<li>Macro_ShowWindow *message*</li>
								<li>Macro_Sleep *time*</li>
								<li>Macro_MainBrowser_Navigate *url*</li>
								<li>Macro_MainBrowser_PrimaryClick *xpath*</li>
								<li>Macro_MainBrowser_Write *xpath* *text*</li>
							</ul>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<ul id="suggestions-list"></ul>
		<div id="hint-div"></div>
	</div>
</template>
<script>
/* eslint no-unused-vars: 0, no-empty: 0, no-unreachable: 0 */
import { DiMaFetchTransformer } from '../helpers/APIconnection';

const diMaFetch = DiMaFetchTransformer('di_ma/browserflows/flows-compiler');

export default {
    mounted() {
    if (!RegExp.escape) {
		// https://stackoverflow.com/a/3561711
		RegExp.escape = text => text.replaceAll(/[/\-\\^$*+?.()|[\]{}]/g, "\\$&" );
	}
	const versionElement = document.getElementById("version");
	const lineNumbersArea = document.getElementById("line-numbers");
	const sourceCodeEditor = document.getElementById("source-code");
	const statisticsTable = document.getElementById("statistics");
	const outputJsonArea = document.getElementById("output-json");
	const errorsDiv = document.getElementById("errors-div");
	const errorsTBody = errorsDiv.querySelector("tbody");
	const buttonPublish = document.getElementById("publish");
	const buttonLoad = document.getElementById("load");
	const suggestionsList = document.getElementById("suggestions-list");
	const hintDiv = document.getElementById("hint-div");
	const protectHTML = html => (html ?? "").replaceAll(/[&<>]/g, f => ({ "&": "&amp;", "<": "&lt;", ">": "&gt;" })[f] ?? f);
	const protectHTMLAttribute = html => (html ?? "").replaceAll(/[&<>'"]/g, f => ({ '"': "&quot;", "&": "&amp;", "<": "&lt;", ">": "&gt;", "'": "&apos;" })[f] ?? f);
	const filterAndSort = (list, string, selectionCb = elem => elem) => {
		const re = new RegExp(string.split("").map(c => RegExp.escape(c)).join(".*?"));
		return list.map(selectionCb).filter(elem => re.exec(elem)).sort((a, b) => {
			if (a.startsWith(string) && b.startsWith(string)) {
				return a.length - b.length;
			}
			if (a.startsWith(string) && !b.startsWith(string)) {
				return -1;
			}
			if (b.startsWith(string) && !a.startsWith(string)) {
				return 1;
			}
			return a.length - b.length;
		});
	};
	window.filterAndSort = filterAndSort;
	const buildSpan = (index, style, textContent, hintHTML) => {
		return `<span style="${style}" start="${index}" data-hint="${protectHTMLAttribute(hintHTML)}">${protectHTML(textContent)}</span>`;
	};
	const createReplacer = (initializer = context => {}, replacers = []) => {
		const replacersAction = Object.fromEntries(replacers.map(replacer => {
			if (replacer.function) {
				return [ replacer.key, replacer.function ];
			} else if (replacer.style) {
				return [ replacer.key, ({ match, index, input, groups, context, parentContext }) => buildSpan(index, replacer.style, match[0]) ];
			} else {
				return [ replacer.key, ({ match, index, input, groups, context, parentContext }) => match[0] ];
			}
		}));
		const regex = new RegExp(replacers.map(replacer => `(?<${replacer.key}>${replacer.regex})`).join("|"), "gs");
		return (source, { startIndex = 0, startLine = 1, startColumn = 0, srcFile = "" }, parentContext) => {
			let line = startLine;
			let column = startColumn;
			let file = srcFile;
			const context = {};
			initializer(context, parentContext);
			return [ source.replaceAll(regex, (...match) => {
				try {
					const index = match[match.length - 3];
					const input = match[match.length - 2];
					const groups = match[match.length - 1];
					const candidates = Object.entries(groups).filter(entry => entry[1] && replacersAction[entry[0]]);
					if (candidates.length) {
						return replacersAction[candidates[0][0]]({ match, index: index + startIndex, line, column, file, input, groups, context, parentContext });
					}
				} finally {
					line += match[0].split("\n").length - 1;
					column = match[0].includes("\n") ? match[0].length - match[0].lastIndexOf("\n") - 1 : column + match[0].length;
				}
			}), context ];
		};
	};
	const flowMetadata = createReplacer((context, parentContext) => {
		context.flowId = "";
		context.flowGroup = "";
		context.name = "";
		context.description = "";
		context.status = false;
		context.iterationVariableKey = undefined;
		context.instructionsURL = undefined;
		context.templateURL = undefined;
		context.timeSavedRatio = 6;
		context.usageRatio = 1;
		context.autoRepeatRowErrorsLimit = 1;
		context.digitalCertificateRequired = undefined;
		context.outputDirectoryVariable = undefined;
		context.userLogFileVariableKey = undefined;
		context.allowMouseInput = true;
		context.allowKeyboardInput = false;
	}, [
		{ key: "flowId", regex: String.raw`(?<flowId_name>@flowId[ \t]+)(?<flowId_value>[0-9a-fA-F]{24}|new|\.\.\.)`, function: ({ match, index, line, column, file, input, groups, context, parentContext }) => {
			context.flowId = groups.flowId_value;
			return buildSpan(index, "color:lightgreen", groups.flowId_name) + buildSpan(index + groups.flowId_name.length, groups.flowId_value === "..." ? "color:red" : groups.flowId_value === "new" ? "color:aqua" : "color:yellow", groups.flowId_value);
		} },
		{ key: "flowGroup", regex: String.raw`(?<flowGroup_name>@flowGroup[ \t]+)(?<flowGroup_value>[^\n]+)`, function: ({ match, index, line, column, file, input, groups, context, parentContext }) => {
			context.flowGroup = groups.flowGroup_value;
			return buildSpan(index, "color:lightgreen", groups.flowGroup_name) + buildSpan(index + groups.flowGroup_name.length, "color:yellow", groups.flowGroup_value);
		} },
		{ key: "status", regex: String.raw`(?<status_name>@status[ \t]+)(?<status_value>true|false)`, function: ({ match, index, line, column, file, input, groups, context, parentContext }) => {
			context.status = groups.status_value === "true";
			return buildSpan(index, "color:lightgreen", groups.status_name) + buildSpan(index + groups.status_name.length, "color:yellow", groups.status_value);
		} },
		{ key: "name", regex: String.raw`(?<name_name>@name[ \t]+)(?<name_value>[^\n]+)`, function: ({ match, index, line, column, file, input, groups, context, parentContext }) => {
			context.name = groups.name_value;
			return buildSpan(index, "color:lightgreen", groups.name_name) + buildSpan(index + groups.name_name.length, "color:yellow", groups.name_value);
		} },
		{ key: "description", regex: String.raw`(?<description_name>@description[ \t]+)(?<description_value>[^\n]+)`, function: ({ match, index, line, column, file, input, groups, context, parentContext }) => {
			context.description = groups.description_value;
			return buildSpan(index, "color:lightgreen", groups.description_name) + buildSpan(index + groups.description_name.length, "color:yellow", groups.description_value);
		} },
		{ key: "timeSavedRatio", regex: String.raw`(?<timeSavedRatio_name>@timeSavedRatio[ \t]+)(?<timeSavedRatio_value>[^\n]+)`, function: ({ match, index, line, column, file, input, groups, context, parentContext }) => {
			context.timeSavedRatio = +groups.timeSavedRatio_value;
			return buildSpan(index, "color:lightgreen", groups.timeSavedRatio_name) + buildSpan(index + groups.timeSavedRatio_name.length, Number.isNaN(+groups.timeSavedRatio_value) ? "color:red" : "color:yellow", groups.timeSavedRatio_value);
		} },
		{ key: "usageRatio", regex: String.raw`(?<usageRatio_name>@usageRatio[ \t]+)(?<usageRatio_value>[^\n]+)`, function: ({ match, index, line, column, file, input, groups, context, parentContext }) => {
			context.usageRatio = +groups.usageRatio_value;
			return buildSpan(index, "color:lightgreen", groups.usageRatio_name) + buildSpan(index + groups.usageRatio_name.length, Number.isNaN(+groups.usageRatio_value) ? "color:red" : "color:yellow", groups.usageRatio_value);
		} },
		{ key: "autoRepeatErrors", regex: String.raw`(?<autoRepeatErrors_name>@autoRepeatErrors[ \t]+)(?<autoRepeatErrors_value>[^\n]+)`, function: ({ match, index, line, column, file, input, groups, context, parentContext }) => {
			context.autoRepeatRowErrorsLimit = +groups.autoRepeatErrors_value;
			return buildSpan(index, "color:lightgreen", groups.autoRepeatErrors_name) + buildSpan(index + groups.autoRepeatErrors_name.length, Number.isNaN(+groups.autoRepeatErrors_value) ? "color:red" : "color:yellow", groups.autoRepeatErrors_value);
		} },
		{ key: "digitalCertificateRequired", regex: String.raw`(?<digitalCertificateRequired_name>@digitalCertificateRequired[ \t]+)(?<digitalCertificateRequired_value>true|false)`, function: ({ match, index, line, column, file, input, groups, context, parentContext }) => {
			context.digitalCertificateRequired = groups.digitalCertificateRequired_value === "true";
			return buildSpan(index, "color:lightgreen", groups.digitalCertificateRequired_name) + buildSpan(index + groups.digitalCertificateRequired_name.length, "color:yellow", groups.digitalCertificateRequired_value);
		} },
		{ key: "outputDirectoryVariable", regex: String.raw`(?<outputDirectoryVariable_name>@outputDirectoryVariable[ \t]+)(?<outputDirectoryVariable_value>\$[a-zA-Z_][a-zA-Z_0-9]*)`, function: ({ match, index, line, column, file, input, groups, context, parentContext }) => {
			context.outputDirectoryVariable = groups.outputDirectoryVariable_value;
			return buildSpan(index, "color:lightgreen", groups.outputDirectoryVariable_name) + buildSpan(index + groups.outputDirectoryVariable_name.length, "color:yellow", groups.outputDirectoryVariable_value);
		} },
		{ key: "userLogFileVariableKey", regex: String.raw`(?<userLogFileVariableKey_name>@userLogFileVariableKey[ \t]+)(?<userLogFileVariableKey_value>true|false)`, function: ({ match, index, line, column, file, input, groups, context, parentContext }) => {
			context.userLogFileVariableKey = groups.userLogFileVariableKey_value === "true";
			return buildSpan(index, "color:lightgreen", groups.userLogFileVariableKey_name) + buildSpan(index + groups.userLogFileVariableKey_name.length, "color:yellow", groups.userLogFileVariableKey_value);
		} },
		{ key: "instructionsURL", regex: String.raw`(?<instructionsURL_name>@instructionsURL[ \t]+)(?<instructionsURL_value>[^\n]+)`, function: ({ match, index, line, column, file, input, groups, context, parentContext }) => {
			context.instructionsURL = groups.instructionsURL_value;
			let isValid = false;
			try {
				new URL(groups.instructionsURL_value);
				isValid = true;
			} catch (err) {}
			return buildSpan(index, "color:lightgreen", groups.instructionsURL_name) + buildSpan(index + groups.instructionsURL_name.length, isValid ? "color:yellow" : "color:red", groups.instructionsURL_value);
		} },
		{ key: "templateURL", regex: String.raw`(?<templateURL_name>@templateURL[ \t]+)(?<templateURL_value>[^\n]+)`, function: ({ match, index, line, column, file, input, groups, context, parentContext }) => {
			context.templateURL = groups.templateURL_value;
			let isValid = false;
			try {
				new URL(groups.templateURL_value);
				isValid = true;
			} catch (err) {}
			return buildSpan(index, "color:lightgreen", groups.templateURL_name) + buildSpan(index + groups.templateURL_name.length, isValid ? "color:yellow" : "color:red", groups.templateURL_value);
		} },
		{ key: "allowPublic", regex: String.raw`(?<public_name>@public[ \t]+)(?<public_value>true|false)`, function: ({ match, index, line, column, file, input, groups, context, parentContext }) => {
			context.accessControl ??= { public: false, users: [], groups: [], userSectors: [], userLocations: [] };
			context.accessControl.public = groups.public_value === "true";
			return buildSpan(index, "color:lightgreen", groups.public_name) + buildSpan(index + groups.public_name.length, "color:yellow", groups.public_value);
		} },
		{ key: "allowUser", regex: String.raw`(?<user_name>@user[ \t]+)(?<user_value>[0-9a-fA-F]{24})`, function: ({ match, index, line, column, file, input, groups, context, parentContext }) => {
			context.accessControl ??= { public: false, users: [], groups: [], userSectors: [], userLocations: [] };
			context.accessControl.users.push(groups.user_value);
			return buildSpan(index, "color:lightgreen", groups.user_name) + buildSpan(index + groups.user_name.length, "color:yellow", groups.user_value);
		} },
		{ key: "allowGroup", regex: String.raw`(?<group_name>@group[ \t]+)(?<group_value>[0-9a-fA-F]{24})`, function: ({ match, index, line, column, file, input, groups, context, parentContext }) => {
			context.accessControl ??= { public: false, users: [], groups: [], userSectors: [], userLocations: [] };
			context.accessControl.groups.push(groups.group_value);
			return buildSpan(index, "color:lightgreen", groups.group_name) + buildSpan(index + groups.group_name.length, "color:yellow", groups.group_value);
		} },
		{ key: "allowMouseInput", regex: String.raw`(?<allowMouseInput_name>@allowMouseInput[ \t]+)(?<allowMouseInput_value>true|false)`, function: ({ match, index, line, column, file, input, groups, context, parentContext }) => {
			context.allowMouseInput = groups.allowMouseInput_value === "true";
			return buildSpan(index, "color:lightgreen", groups.allowMouseInput_name) + buildSpan(index + groups.allowMouseInput_name.length, "color:yellow", groups.allowMouseInput_value);
		} },
		{ key: "allowKeyboardInput", regex: String.raw`(?<allowKeyboardInput_name>@allowKeyboardInput[ \t]+)(?<allowKeyboardInput_value>true|false)`, function: ({ match, index, line, column, file, input, groups, context, parentContext }) => {
			context.allowKeyboardInput = groups.allowKeyboardInput_value === "true";
			return buildSpan(index, "color:lightgreen", groups.allowKeyboardInput_name) + buildSpan(index + groups.allowKeyboardInput_name.length, "color:yellow", groups.allowKeyboardInput_value);
		} },
		{ key: "all", regex: String.raw`.`, style: "color: gray" },
	]);
	const interpolateString = createReplacer((context, parentContext) => {
	}, [
		{ key: "variable", regex: String.raw`\$[a-zA-Z_][a-zA-Z_0-9]*(?=\b)`, function: ({ match, index, line, column, file, input, groups, context, parentContext }) => {
			const result = parentContext.FindVariable(match[0], false);
			if (result) {
				Object.assign(result, { read: true });
				return buildSpan(index, "color:orange", match[0]);
			}
			return buildSpan(index, "color:red", match[0]);
		} },
		{ key: "all", regex: String.raw`(?:[^$]|\$(?=[^a-zA-Z_]))+`, style: "color:yellow" },
	]);
	const replaceSourceToHTML = createReplacer((context, parentContext) => {
		context.flow = {
			_id: undefined,
			group: undefined,
			status: false,
			name: "Unnamed flow",
			description: "",
			flowInputs: [
//				{ key: "log", required: false, type: "LogStream", variableKey: "log" },
			],
			steps: [],
			variables: [
//				{ key: "log", value: "", variableType: "ExecutionTime" },
			],
		};
		context.scopes = [
			{ owner: "root", status: { type: "code" }, variables: {}, expression: [ [] ], identifiers: {}, description: "" },
		];
		context.errors = [];
		context.suggestions = [];
		context.activeTempVariables = {};
		context.externals = {};
		context.definedIdentifiers = {};
		context.loopControlPoints = [];
		context.CurrentScope = () => {
			return context.scopes.slice(-1)[0];
		};
		context.ParentScope = () => {
			return context.scopes.slice(-2, -1)[0];
		};
		context.CurrentScopePosition = () => {
			return context.CurrentScope().expression.slice(-1)[0];
		};
		context.ParentScopePosition = () => {
			return context.ParentScope().expression.slice(-1)[0];
		};
		context.FindVariable = (name, createIfNotFound=false) => {
			if (name.match("^\\$__temp[0-9]+$")) {
				return { written: true, read: true, initialValue: undefined, currentValue: undefined, type: undefined, writable: true };
			}
			const result = context.scopes.reduceRight((accu, curr) => accu ? accu : curr.variables[name], undefined);
			if (!result && createIfNotFound) {
				return context.CurrentScope().variables[name] = { written: false, read: false, initialValue: undefined, currentValue: undefined, type: undefined, writable: true };
			}
			return result;
		};
		context.EnumerateVariables = () => {
			return context.scopes.reduceRight((accu, curr) => Object.assign(accu, curr.variables), {});
		};
		context.GetDescription = () => context.scopes.reduceRight((accu, curr) => accu || curr.description, "");
		context.FormatSourceRef = ref => ref.file + ":" + ref.line + ":" + ref.column;
		context.FormatSourceRefAndDescription = ref => {
			const description = context.GetDescription();
			return (description ? description + " " : "") + "[" + context.FormatSourceRef(ref) + "]";
		};
		context.PutOnSource = expressionNode => {
			return expressionNode?.type === "string" ? "'" + expressionNode.value.replaceAll("['\\\\]", c => ({ "'": "\\'", "\\": "\\\\" })[c]) + "'" : expressionNode?.value;
		};
		context.RequestTempVariable = expressionNode => {
			let i = -1;
			while (context.activeTempVariables["$__temp" + ++i]);
			const variable = "$__temp" + i;
			if (context.activeTempVariables[variable] === undefined) {
				context.flow.variables.push({ key: variable, value: "", variableType: "ExecutionTime" });
			}
			context.activeTempVariables[variable] = context.FormatSourceRefAndDescription(expressionNode);
			return variable;
		};
		context.FreeTempVariable = variable => {
			if (!context.activeTempVariables[variable]) {
				context.errors.push([ "", "Freeing already freed temp variable", variable ]);
			}
			context.activeTempVariables[variable] = false;
		};
		context.BuildFromExpressionNode = (expressionNode) => {
			let newValuesList = [];
			let toAppend = { steps: [] };
			switch (expressionNode.type) {
				case "number":
					newValuesList.push({ type: expressionNode.type, value: +expressionNode.value, toFree: false });
					break;
				case "string":
					newValuesList.push({ type: expressionNode.type, value: expressionNode.value, toFree: false });
					break;
				case "variable":
					newValuesList.push({ type: expressionNode.type, value: expressionNode.value, toFree: false });
					break;
				case "constant":
					newValuesList.push({ type: expressionNode.type, value: expressionNode.value, toFree: false });
					break;
				case "template":
					newValuesList.push({ type: "variable", value: context.RequestTempVariable(expressionNode), toFree: true });
					toAppend.steps.push({ stepType: "VariableOperation", action: "ReplaceTemplateSimple", variables: [ newValuesList[0].value, expressionNode.value ], description: context.FormatSourceRefAndDescription(expressionNode) });
					break;
				case "virtualGrouper":
				case "grouper": {
					newValuesList.push(...expressionNode.scope.expression.flatMap(subExpression => {
						let newValuesList = [];
						if (subExpression.length === 0) {
							newValuesList.push({ type: "null", value: null });
						} else if (subExpression.length === 1) {
							let { newValuesList: newValue, toAppend: newToAppend } = context.BuildFromExpressionNode(subExpression[0]);
							if (newValue.length > 1) {
								context.errors.push([ context.FormatSourceRefAndDescription(expressionNode), "Grouper has more than one expression, excess expressions will be ignored" ]);
							}
							newValuesList.push(newValue[0]);
							toAppend.steps.push(...newToAppend.steps);
						} else if (subExpression.length === 2 && subExpression[1].type === "accessor" && subExpression[1].scope.expression.length === 1) {
							const { newValuesList: left, toAppend: leftNewToAppend } = context.BuildFromExpressionNode(subExpression[0]);
							const { newValuesList: right, toAppend: rightNewToAppend } = context.BuildFromExpressionNode({
								index: subExpression[1].scope.expression[0][0].index,
								line: subExpression[1].scope.expression[0][0].line,
								column: subExpression[1].scope.expression[0][0].column,
								file: subExpression[1].scope.expression[0][0].file,
								type: "virtualGrouper",
								value: "()",
								scope: { owner: "virtualGrouper", status: { type: "code" }, variables: {}, expression: [ subExpression[1].scope.expression[0] ] },
							});
							if (left.length > 1) {
								context.errors.push([ context.FormatSourceRefAndDescription(expressionNode), "Grouper has more than one expression, excess expressions will be ignored" ]);
							}
							if (right.length > 1) {
								context.errors.push([ context.FormatSourceRefAndDescription(expressionNode), "Grouper has more than one expression, excess expressions will be ignored" ]);
							}
							newValuesList.push({ type: "variable", value: context.RequestTempVariable(expressionNode), toFree: true });
							toAppend.steps.push(...rightNewToAppend.steps, ...leftNewToAppend.steps);
							toAppend.steps.push({ stepType: "JSONOperation", action: "GetValue", variables: [ newValuesList[0].value, left[0].value, right[0].value, "" ], description: context.FormatSourceRefAndDescription(expressionNode) });
							if (left[0].toFree) {
								context.FreeTempVariable(left[0].value);
							}
							if (right[0].toFree) {
								context.FreeTempVariable(right[0].value);
							}
						} else if (subExpression.length === 2 && subExpression[0].type === "operator" && subExpression[0].value === "-" && subExpression[1].type === "number") {
							newValuesList.push({ type: "number", value: -subExpression[1].value });
						} else if (subExpression.length === 2 && subExpression[0].type === "function" && subExpression[1].type === "grouper") {
							let tempVariables = [];
							const args = subExpression[1].scope.expression.reduce((accu, curr) => {
								if (curr.length === 0) {
									return Object.assign(accu, { next: "" });
								}
								if (curr[0].type === "namedParameter") {
									accu.next = curr[0].value;
								}
								const { newValuesList: newValue, toAppend: newToAppend } = context.BuildFromExpressionNode({
									index: curr[0].index,
									line: curr[0].line,
									column: curr[0].column,
									file: curr[0].file,
									type: "virtualGrouper",
									value: "()",
									scope: { owner: "virtualGrouper", status: { type: "code" }, variables: {}, expression: [ curr.slice(curr[0].type === "namedParameter" ? 1 : 0) ] },
								});
								if (newValue.length > 1) {
									context.errors.push([ context.FormatSourceRefAndDescription(expressionNode), "Grouper has more than one expression, excess expressions will be ignored" ]);
								}
								toAppend.steps.push(...newToAppend.steps);
								(accu.next ? (accu.list[accu.next] ??= []) : accu.list).push(newValue[0]);
								if (newValue[0].toFree) {
									tempVariables.push(newValue[0].value);
								}
								return Object.assign(accu, { next: "" });
							}, { list: [], next: "" }).list;
							let returnValue = {};
							const newToAppend = subExpression[0].compile(context, subExpression, args, returnValue);
							toAppend.steps.push(...newToAppend.steps);
							tempVariables.forEach(variable => context.FreeTempVariable(variable));
							newValuesList.push(returnValue);
						} else if (subExpression.length === 3 && subExpression[1].type === "operator") {
							const transformations = {
								"+": [ "Add", (a, b) => ({ type: "number", value: +a + +b, toFree: false }) ],
								"-": [ "Substract", (a, b) => ({ type: "number", value: a - b, toFree: false }) ],
								"*": [ "Multiply", (a, b) => ({ type: "number", value: a * b, toFree: false }) ],
								"/": [ "Divide", (a, b) => ({ type: "number", value: a / b, toFree: false }) ],
								"%": [ "Modulo", (a, b) => ({ type: "number", value: a % b, toFree: false }) ],
								"==": [ "Equal", (a, b) => ({ type: "number", value: a == b ? 1 : 0, toFree: false }) ],
								"!=": [ "Distinct", (a, b) => ({ type: "number", value: a != b ? 1 : 0, toFree: false }) ],
								">": [ "GreaterThan", (a, b) => ({ type: "number", value: +a > +b ? 1 : 0, toFree: false }) ],
								"<": [ "LesserThan", (a, b) => ({ type: "number", value: +a < +b ? 1 : 0, toFree: false }) ],
								">=": [ "GreaterThanOrEqual", (a, b) => ({ type: "number", value: +a >= +b ? 1 : 0, toFree: false }) ],
								"<=": [ "LesserThanOrEqual", (a, b) => ({ type: "number", value: +a <= +b ? 1 : 0, toFree: false }) ],
								"<>": [ "Concat", (a, b) => ({ type: "string", value: a + "" + b, toFree: false }) ],
								"&&": [ "And", (a, b) => ({ type: "number", value: +a & +b, toFree: false }) ],
								"||": [ "Or", (a, b) => ({ type: "number", value: +a | +b, toFree: false }) ],
							};
							const { newValuesList: left, toAppend: leftNewToAppend } = context.BuildFromExpressionNode(subExpression[0]);
							const { newValuesList: right, toAppend: rightNewToAppend } = context.BuildFromExpressionNode(subExpression[2]);
							toAppend.steps.push(...leftNewToAppend.steps, ...rightNewToAppend.steps);
							if (left.length > 1) {
								context.errors.push([ context.FormatSourceRefAndDescription(expressionNode), "Grouper has more than one expression, excess expressions will be ignored" ]);
							}
							if (right.length > 1) {
								context.errors.push([ context.FormatSourceRefAndDescription(expressionNode), "Grouper has more than one expression, excess expressions will be ignored" ]);
							}
							if ([ "number", "string" ].includes(left[0].type) && [ "number", "string" ].includes(right[0].type)) {
								newValuesList.push(transformations[subExpression[1].value][1](left[0].value, right[0].value));
							} else {
								newValuesList.push({ type: "variable", value: context.RequestTempVariable(expressionNode), toFree: true });
								toAppend.steps.push({ stepType: "VariableOperation", action: transformations[subExpression[1].value][0], variables: [ newValuesList[0].value, left[0].value, right[0].value ], description: context.FormatSourceRefAndDescription(expressionNode) });
							}
							if (left[0].toFree) {
								context.FreeTempVariable(left[0].value);
							}
							if (right[0].toFree) {
								context.FreeTempVariable(right[0].value);
							}
						} else if (subExpression.length === 5 && subExpression[1].type === "operator" && subExpression[1].value === "?" && subExpression[3].type === "operator" && subExpression[3].value === ":") {
							const { newValuesList: condition, toAppend: conditionNewToAppend } = context.BuildFromExpressionNode(subExpression[0]);
							const { newValuesList: left, toAppend: leftNewToAppend } = context.BuildFromExpressionNode(subExpression[2]);
							const { newValuesList: right, toAppend: rightNewToAppend } = context.BuildFromExpressionNode(subExpression[4]);
							if (condition.length > 1) {
								context.errors.push([ context.FormatSourceRefAndDescription(expressionNode), "Grouper has more than one expression, excess expressions will be ignored" ]);
							}
							if (left.length > 1) {
								context.errors.push([ context.FormatSourceRefAndDescription(expressionNode), "Grouper has more than one expression, excess expressions will be ignored" ]);
							}
							if (right.length > 1) {
								context.errors.push([ context.FormatSourceRefAndDescription(expressionNode), "Grouper has more than one expression, excess expressions will be ignored" ]);
							}
							toAppend.steps.push(...conditionNewToAppend.steps, ...leftNewToAppend.steps, ...rightNewToAppend.steps);
							newValuesList.push({ type: "variable", value: context.RequestTempVariable(expressionNode), toFree: true });
							toAppend.steps.push({ stepType: "VariableOperation", action: "Conditional", variables: [ newValuesList[0].value, condition[0].value, left[0].value, right[0].value ], description: context.FormatSourceRefAndDescription(expressionNode) });
							if (condition[0].toFree) {
								context.FreeTempVariable(condition[0].value);
							}
							if (left[0].toFree) {
								context.FreeTempVariable(left[0].value);
							}
							if (right[0].toFree) {
								context.FreeTempVariable(right[0].value);
							}
						} else {
							let status = "init";
							let conditionalsLevel = 0;
							for (let i = 0; i < expressionNode.scope.expression[0].length; i++) {
								const currentNode = expressionNode.scope.expression[0][i];
								const currentNodeType = currentNode.type === "virtualGrouper" ? "grouper" : currentNode.type
								switch (status + ";" + currentNodeType) {
									case "init;number":
									case "init;string":
									case "init;template":
									case "init;variable":
									case "init;constant":
									case "init;function":
									case "init;grouper":
									case "number;operator":
									case "string;operator":
									case "template;operator":
									case "variable;operator":
									case "constant;operator":
									case "operator;number":
									case "operator;string":
									case "operator;template":
									case "operator;variable":
									case "operator;constant":
									case "operator;grouper":
									case "operator;function":
									case "variable;accessor":
									case "constant;accessor":
									case "function;grouper":
									case "accessor;operator":
									case "accessor;accessor":
									case "grouper;operator":
									case "grouper;accessor":
										if (currentNodeType === "operator" && currentNode.value === "?") {
											conditionalsLevel++;
										} else if (currentNodeType === "operator" && currentNode.value === ":") {
											if (--conditionalsLevel < 0) {
												status = "";
												break;
											}
										}
										status = currentNodeType;
										break;
									default:
										console.log(status + ";" + currentNodeType);
										status = "(error)";
								}
							}
							if ([ "number", "string", "template", "variable", "constant", "accessor", "grouper" ].includes(status) && conditionalsLevel === 0) {
								const listToProcess = [ ...expressionNode.scope.expression[0] ];
								for (let i = 0; i < listToProcess.length; i++) {
									if ([ "variable", "constant", "accessor", "grouper", "virtualGrouper" ].includes(listToProcess[i].type) && listToProcess[i + 1]?.type === "accessor" || listToProcess[i].type === "function" && listToProcess[i + 1]?.type === "grouper") {
										listToProcess.splice(i, 0, {
											index: listToProcess[i].index,
											line: listToProcess[i].line,
											column: listToProcess[i].column,
											file: listToProcess[i].file,
											type: "virtualGrouper",
											value: "()",
											scope: { owner: "virtualGrouper", status: { type: "code" }, variables: {}, expression: [ listToProcess.splice(i, 2) ] },
										});
										i = -1;
									}
									if (listToProcess[i + 1]?.type === "operator" && listToProcess[i + 3]?.type === "operator" && listToProcess[i + 5]?.type === "operator" && listToProcess[i + 1]?.value === "?" && listToProcess[i + 3]?.value === ":" && listToProcess[i + 5]?.value === ":") {
										listToProcess.splice(i, 0, {
											index: listToProcess[i].index,
											line: listToProcess[i].line,
											column: listToProcess[i].column,
											file: listToProcess[i].file,
											type: "virtualGrouper",
											value: "()",
											scope: { owner: "virtualGrouper", status: { type: "code" }, variables: {}, expression: [ listToProcess.splice(i, 5) ] },
										});
										i = -1;
									}
									if (
										listToProcess[i + 1]?.type === "operator" && listToProcess[i + 3]?.type === "operator" && listToProcess[i + 1]?.level >= listToProcess[i + 3]?.level
										&&
										![ "?", ":" ].includes(listToProcess[i + 1]?.value)
									) {
										listToProcess.splice(i, 0, {
											index: listToProcess[i].index,
											line: listToProcess[i].line,
											column: listToProcess[i].column,
											file: listToProcess[i].file,
											type: "virtualGrouper",
											value: "()",
											scope: { owner: "virtualGrouper", status: { type: "code" }, variables: {}, expression: [ listToProcess.splice(i, 3) ] },
										});
										i = -1;
									}
									if (i === listToProcess.length - 1 && listToProcess.length > 2) {
										if (listToProcess.length > 4 && listToProcess[i - 1].type === "operator" && listToProcess[i - 3].type === "operator" && listToProcess[i - 3].value === "?" && listToProcess[i - 1].value === ":") {
											listToProcess.push({
												index: listToProcess[i - 4].index,
												line: listToProcess[i - 4].line,
												column: listToProcess[i - 4].column,
												file: listToProcess[i - 4].file,
												type: "virtualGrouper",
												value: "()",
												scope: { owner: "virtualGrouper", status: { type: "code" }, variables: {}, expression: [ listToProcess.splice(-5) ] },
											});
										} else {
											listToProcess.push({
												index: listToProcess[i - 2].index,
												line: listToProcess[i - 2].line,
												column: listToProcess[i - 2].column,
												file: listToProcess[i - 2].file,
												type: "virtualGrouper",
												value: "()",
												scope: { owner: "virtualGrouper", status: { type: "code" }, variables: {}, expression: [ listToProcess.splice(-3) ] },
											});
										}
										i = -1;
									}
								}
								const { newValuesList: left, toAppend: leftNewToAppend } = context.BuildFromExpressionNode(listToProcess[0]);
								if (left.length > 1) {
									context.errors.push([ context.FormatSourceRefAndDescription(expressionNode), "Grouper has more than one expression, excess expressions will be ignored" ]);
								}
								newValuesList.push(left[0]);
								toAppend.steps.push(...leftNewToAppend.steps);
							} else {
								context.errors.push([ context.FormatSourceRefAndDescription(expressionNode), "Unable to process expression node of type grouper", expressionNode ]);
							}
						}
						return newValuesList.length ? newValuesList : [{ type: "null", value: null }];
					}));
				} break;
				default:
					context.errors.push([ context.FormatSourceRefAndDescription(expressionNode), "Unable to process expression node", expressionNode ]);
					break;
			}
			return { newValuesList, toAppend };
		};
		context.BuildFromExpression = (expression) => {
			//BuildFromExpression start
			let toAppend = { steps: [] };
			if (!expression.length) {
			} else if (expression[0].type === "initialize") {
				if (!(expression[0].to.length === 1 && expression[0].to[0].type === "variable" || expression[0].to[0].type === "constant")) {
					context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Initializing not a variable or constant is not accepted" ]);
				} else {
					const { newValuesList, toAppend: newToAppend } = context.BuildFromExpressionNode({
						index: expression[0].index,
						line: expression[0].line,
						column: expression[0].column,
						file: expression[0].file,
						type: "virtualGrouper",
						value: "()",
						scope: { owner: "virtualGrouper", status: { type: "code" }, variables: {}, expression: [ expression.slice(1) ] },
					});
					if (newValuesList.length > 1) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Grouper has more than one expression, excess expressions will be ignored" ]);
					}
					Object.assign(context.FindVariable(expression[0].to[0].value), { initialValue: newValuesList[0] });
					toAppend.steps.push(...newToAppend.steps);
					toAppend.steps.push({ stepType: "VariableOperation", action: "Set", variables: [ expression[0].to[0].value, newValuesList[0].value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					if (newValuesList[0].toFree) {
						context.FreeTempVariable(newValuesList[0].value);
					}
				}
			} else if (expression[0].type === "assign") {
				if (!(expression[0].to.length === 1 && expression[0].to[0].type === "variable" || expression[0].to.length === 2 && expression[0].to[0].type === "variable" && expression[0].to[1].type === "accessor")) {
					context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Assigning to not a variable, or 2 tokens not being a variable and an accessor is not accepted" ]);
				} else {
					const { newValuesList, toAppend: newToAppend } = context.BuildFromExpressionNode({
						index: expression[0].index,
						line: expression[0].line,
						column: expression[0].column,
						file: expression[0].file,
						type: "virtualGrouper",
						value: "()",
						scope: { owner: "virtualGrouper", status: { type: "code" }, variables: {}, expression: [ expression.slice(1) ] },
					});
					if (newValuesList.length > 1) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Grouper has more than one expression, excess expressions will be ignored" ]);
					}
					toAppend.steps.push(...newToAppend.steps);
					if (expression[0].to.length === 1) {
						toAppend.steps.push({ stepType: "VariableOperation", action: "Set", variables: [ expression[0].to[0].value, newValuesList[0].value ], description: context.FormatSourceRefAndDescription(expression[0]) });
						Object.assign(context.FindVariable(expression[0].to[0].value), { currentValue: newValuesList[0] });
					} else {
						const { newValuesList: newValuesKey, toAppend: newToAppendKey } = context.BuildFromExpressionNode({
							index: expression[0].to[1].scope.expression[0][0].index,
							line: expression[0].to[1].scope.expression[0][0].line,
							column: expression[0].to[1].scope.expression[0][0].column,
							file: expression[0].to[1].scope.expression[0][0].file,
							type: "virtualGrouper",
							value: "()",
							scope: { owner: "virtualGrouper", status: { type: "code" }, variables: {}, expression: expression[0].to[1].scope.expression },
						});
						if (newValuesKey.length > 1) {
							context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Grouper has more than one expression, excess expressions will be ignored" ]);
						}
						const foundVariable = context.FindVariable(expression[0].to[0].value);
						if (foundVariable) {
							Object.assign(foundVariable, { currentValue: { type: "object", value: "..." } });
						} else {
							context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Variable not found" ]);
						}
						toAppend.steps.push(...newToAppendKey.steps);
						toAppend.steps.push({ stepType: "JSONOperation", action: "SetValue", variables: [ expression[0].to[0].value, expression[0].to[0].value, newValuesKey[0].value, newValuesList[0].value ], description: context.FormatSourceRefAndDescription(expression[0]) });
						if (newValuesKey[0].toFree) {
							context.FreeTempVariable(newValuesKey[0].value);
						}
					}
					if (newValuesList[0].toFree) {
						context.FreeTempVariable(newValuesList[0].value);
					}
				}
			} else if (expression[0].type === "macro") {
				let tempVariables = [];
				let expressionArguments = expression.slice(1);
				const argumentValues = expressionArguments.reduce((accu, curr) => {
					if (curr.type === "namedParameter") {
						return Object.assign(accu, { next: curr.value });
					}
					const { newValuesList, toAppend: newToAppend } = context.BuildFromExpressionNode(curr);
					toAppend.steps.push(...newToAppend.steps);
					if (newValuesList.length > 1) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Grouper has more than one expression, excess expressions will be ignored" ]);
					}
					(accu.next ? (accu.list[accu.next] ??= []) : accu.list).push(newValuesList[0].value);
					if (newValuesList[0].toFree) {
						tempVariables.push(newValuesList[0].value);
					}
					return Object.assign(accu, { next: "" });
				}, { list: [], next: "" }).list;
				switch (expression[0].value) {
					case "Macro_AddInvisibleInput_LogStream":
						context.flow.flowInputs.push({ key: "input-" + argumentValues[0]?.substring(1), variableKey: argumentValues[0], type: "LogStream" });
						break;
					case "Macro_AddInvisibleInput_StreamIterator":
						context.flow.flowInputs.push({ key: "input-" + argumentValues[0]?.substring(1), variableKey: argumentValues[0], type: "StreamIterator" });
						break;
					case "Macro_AddInvisibleInput_UserLogStream":
						context.flow.flowInputs.push({ key: "input-" + argumentValues[0]?.substring(1), variableKey: argumentValues[0], type: "LogStream" });
						context.flow.logStreamVariableKey = "input-" + argumentValues[0]?.substring(1);
						break;
					case "Macro_AddRequiredInput_Legacy_Excel":
						context.flow.flowInputs.push({ key: "input-" + argumentValues[0]?.substring(1), variableKey: argumentValues[0], required: true, type: "SelectExcelDialog", template: "SelectionDialog", config: { accept: ".xlsx" }, title: "", hintText: "" });
						break;
					case "Macro_AddRequiredInput_DownloadFolder":
						context.flow.flowInputs.push({ key: "input-" + argumentValues[0]?.substring(1), variableKey: argumentValues[0], required: true, type: "DownloadFolder", template: "SelectionDialog", config: Object.assign({ directory: true }, argumentValues.title && { title: argumentValues.title[0] }, argumentValues.hint && { hintText: argumentValues.hint[0] }) });
						break;
					case "Macro_AddRequiredInput_ExcelIterator":
						if (argumentValues.schema) {
							try {
								const schema = JSON.parse(argumentValues.schema);
								if (!Array.isArray(schema)) {
									throw new Error("Schema must be an array");
								}
								if (schema.some(elem => typeof elem !== "object" || ![ "field", "required", "tooltip", "match" ].every(key => Object.hasOwn(elem, key)) || ![ "pattern", "flags" ].every(key => Object.hasOwn(elem.match, key)))) {
									throw new Error("Schema objects must have this structure: { field, required, tooltip, match: { pattern, flags } }");
								}
								context.flow.flowInputs.push({ key: "input-" + argumentValues[0]?.substring(1), variableKey: argumentValues[0], required: true, type: "StreamIterator", template: "DataSchema", config: Object.assign({ accept: ".xlsx", schema }, argumentValues.title && { title: argumentValues.title[0] }, argumentValues.hint && { hintText: argumentValues.hint[0] }, argumentValues.headerRowIndex && { headerRowIndex: +argumentValues.headerRowIndex[0] }) });
							} catch (err) {
								context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Not a valid JSON value", err.message, argumentValues.schema ]);
							}
						} else {
							context.flow.flowInputs.push({ key: "input-" + argumentValues[0]?.substring(1), variableKey: argumentValues[0], required: true, type: "StreamIterator", template: "SelectionDialog", config: Object.assign({ accept: ".xlsx" }, argumentValues.title && { title: argumentValues.title[0] }, argumentValues.hint && { hintText: argumentValues.hint[0] }, argumentValues.headerRowIndex && { headerRowIndex: +argumentValues.headerRowIndex[0] }) });
						}
						if (argumentValues.selectColumn) {
							context.flow.flowInputs.push({ key: "input-" + argumentValues.selectColumn[0]?.substring(1), variableKey: argumentValues.selectColumn[0], required: false, type: "FileNameColumn", template: "ExcelColumnSelector", config: Object.assign({ related: "input-" + argumentValues[0]?.substring(1) }, argumentValues.columnTitle && { title: argumentValues.columnTitle[0] }, argumentValues.columnHint && { hintText: argumentValues.columnHint[0] }) });
						}
						if (argumentValues.autoIterate && argumentValues.autoIterate[0] === 1) {
							context.flow.iterationVariableKey = argumentValues[0];
						}
						if (argumentValues.outputVariable && argumentValues.outputFileName) {
							context.flow.flowOutput = { key: "output-" + argumentValues[0]?.substring(1), outputType: "Excel", variableKey: argumentValues.outputVariable[0], outputFileName: argumentValues.outputFileName[0], useInputFileNameFromInputKeyExcelData: "input-" + argumentValues[0]?.substring(1) };
						}
						break;
					case "Macro_AddRequiredInput_ExcelIteratorWithFilenameColumn":
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Deprecated macro Macro_AddRequiredInput_ExcelIteratorWithFilenameColumn, use Macro_AddRequiredInput_ExcelIterator instead" ]);
						if (argumentValues.schema) {
							try {
								const schema = JSON.parse(argumentValues.schema);
								if (!Array.isArray(schema)) {
									throw new Error("Schema must be an array");
								}
								if (schema.some(elem => typeof elem !== "object" || ![ "field", "required", "tooltip", "match" ].every(key => Object.hasOwn(elem, key)) || ![ "pattern", "flags" ].every(key => Object.hasOwn(elem.match, key)))) {
									throw new Error("Schema objects must have this structure: { field, required, tooltip, match: { pattern, flags } }");
								}
								context.flow.flowInputs.push({ key: "input-" + argumentValues[0]?.substring(1), variableKey: argumentValues[0], required: true, type: "StreamIterator", template: "DataSchema", config: Object.assign({ accept: ".xlsx", schema }, argumentValues.title && { title: argumentValues.title[0] }, argumentValues.hint && { hintText: argumentValues.hint[0] }) });
								context.flow.flowInputs.push({ key: "input-" + argumentValues[1]?.substring(1), variableKey: argumentValues[1], required: false, type: "FileNameColumn", template: "ExcelColumnSelector", config: Object.assign({ related: "input-" + argumentValues[0]?.substring(1) }, argumentValues.title2 && { title: argumentValues.title2[0] }, argumentValues.hint2 && { hintText: argumentValues.hint2[0] }) });
							} catch (err) {
								context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Not a valid JSON value", err.message, argumentValues.schema ]);
							}
						} else {
							context.flow.flowInputs.push({ key: "input-" + argumentValues[0]?.substring(1), variableKey: argumentValues[0], required: true, type: "StreamIterator", template: "SelectionDialog", config: Object.assign({ accept: ".xlsx" }, argumentValues.title && { title: argumentValues.title[0] }, argumentValues.hint && { hintText: argumentValues.hint[0] }) });
							context.flow.flowInputs.push({ key: "input-" + argumentValues[1]?.substring(1), variableKey: argumentValues[1], required: false, type: "FileNameColumn", template: "ExcelColumnSelector", config: Object.assign({ related: "input-" + argumentValues[0]?.substring(1) }, argumentValues.title2 && { title: argumentValues.title2[0] }, argumentValues.hint2 && { hintText: argumentValues.hint2[0] }) });
						}
						if (argumentValues.autoIterate && argumentValues.autoIterate[0] === 1) {
							context.flow.iterationVariableKey = argumentValues[0];
						}
						break;
					case "Macro_AddRequiredInput_MultipleCertificates":
						context.flow.flowInputs.push({ key: "input-" + argumentValues[0]?.substring(1), variableKey: argumentValues[0], required: true, type: "SelectMultipleCertificates", template: "CertificateSelector", config: { multiple: true } });
						if (argumentValues.autoIterate && argumentValues.autoIterate[0] === 1) {
							context.flow.iterationVariableKey = argumentValues[0];
						}
						break;
					case "Macro_AddRequiredInput_Selector":
						context.flow.flowInputs.push({ key: "input-" + argumentValues[0]?.substring(1), variableKey: argumentValues[0], required: true, type: "ImmediateValue", template: "Selector", config: { items: argumentValues.slice(1).map(elem => ({ label: elem, value: elem })) } });
						break;
					case "Macro_AddRequiredInput_Date":
						context.flow.flowInputs.push({ key: "input-" + argumentValues[0]?.substring(1), variableKey: argumentValues[0], required: true, type: "ImmediateValue", template: "DateSelection", config: Object.assign({}, argumentValues.title && { title: argumentValues.title[0] }, argumentValues.subtitle && { subtitle: argumentValues.subtitle[0] }, argumentValues.range && argumentValues.range[0] === 1 && { range: true }) });
						break;
					case "Macro_AddInput_SendEmailOption":
						context.flow.flowInputs.push({ key: "input-" + argumentValues[0]?.substring(1), variableKey: argumentValues[0], required: false, type: "SelectSendEmail", template: "Toggle", config: Object.assign({}, argumentValues.title && { title: argumentValues.title[0] }, argumentValues.hint && { hintText: argumentValues.hint[0] }), value: false });
						break;
					case "Macro_AddRequiredInput_SingleCertificate":
						context.flow.flowInputs.push({ key: "input-" + argumentValues[0]?.substring(1), variableKey: argumentValues[0], required: true, type: "SelectSingleCertificate", template: "CertificateSelector", config: {} });
						break;
					case "Macro_AddMemory": {
						const variable = context.RequestTempVariable(expression[0]);
						const random = Math.random().toString(36).substring(2);
						context.flow.flowInputs.push({ key: "memory-" + argumentValues[0]?.substring(1), variableKey: argumentValues[0], type: "StreamIterator" });
						toAppend.steps.push({ stepType: "IteratorOperation", action: "Available", variables: [ variable, argumentValues[0] ], description: context.FormatSourceRefAndDescription(expression[0]) });
						toAppend.steps.push({ stepType: "VariableControl", action: "BranchNotEqual", variables: [ variable, "0", "alreadyInit-" + random ], description: context.FormatSourceRefAndDescription(expression[0]) });
						toAppend.steps.push({ stepType: "IteratorOperation", action: "Write", variables: [ "", argumentValues[0], argumentValues[1] ], description: context.FormatSourceRefAndDescription(expression[0]) });
						toAppend.steps.push({ stepType: "VariableControl", action: "Sleep", key: "alreadyInit-" + random, variables: [ 0 ], description: context.FormatSourceRefAndDescription(expression[0]) });
						context.FreeTempVariable(variable);
					} break;
					case "Macro_Available":
						toAppend.steps.push({ stepType: "IteratorOperation", action: "Available", variables: [ argumentValues[0], argumentValues[1] ], description: context.FormatSourceRefAndDescription(expression[0]) });
						break;
					case "Macro_Read":
						toAppend.steps.push({ stepType: "IteratorOperation", action: "Read", variables: [ argumentValues[0], argumentValues[1] ], description: context.FormatSourceRefAndDescription(expression[0]) });
						break;
					case "Macro_Write":
						toAppend.steps.push({ stepType: "IteratorOperation", action: "Write", variables: [ "", argumentValues[0], argumentValues[1] ], description: context.FormatSourceRefAndDescription(expression[0]) });
						break;
					case "Macro_ReadMultiple":
						toAppend.steps.push({ stepType: "IteratorOperation", action: "ReadMultiple", variables: [ argumentValues[0], argumentValues[1], argumentValues[2] ?? "" ], description: context.FormatSourceRefAndDescription(expression[0]) });
						break;
					case "Macro_WriteMultiple":
						toAppend.steps.push({ stepType: "IteratorOperation", action: "WriteMultiple", variables: [ "", argumentValues[0], argumentValues[1] ], description: context.FormatSourceRefAndDescription(expression[0]) });
						break;
					case "Macro_ShowWindow":
						toAppend.steps.push(Object.assign({ stepType: "VariableControl", action: "ShowWindow", variables: [ ...(argumentValues.length === 1 ? [ "", argumentValues[0] ] : [ argumentValues[0], argumentValues[1] ]), "", "" ], description: context.FormatSourceRefAndDescription(expression[0]), timeout: argumentValues.catchTimeout && !Number.isNaN(+argumentValues.catchTimeout[0]) ? +argumentValues.catchTimeout[0] : 10000, catchTimeout: argumentValues.catchTimeout && !Number.isNaN(+argumentValues.catchTimeout[0]) }, argumentValues.disableInteractionDetection && argumentValues.disableInteractionDetection[0] === 1 ? { disablePause: true } : {}));
						break;
					case "Macro_Sleep":
						toAppend.steps.push({ stepType: "VariableControl", action: "Sleep", variables: [ argumentValues[0] ], description: context.FormatSourceRefAndDescription(expression[0]), timeout: !Number.isNaN(+argumentValues[0]) && +argumentValues[0] >= 9500 ? +argumentValues[0] + 500 : undefined });
						break;
					case "Macro_MainBrowser_Create":
						toAppend.steps.push({ stepType: "BrowserAction", action: "CreateSeleniumBrowser", variables: [], description: context.FormatSourceRefAndDescription(expression[0]) });
						break;
					case "Macro_MainBrowser_Dispose":
						toAppend.steps.push({ stepType: "BrowserAction", action: "DisposeSeleniumBrowser", variables: [], description: context.FormatSourceRefAndDescription(expression[0]) });
						break;
					case "Macro_MainBrowser_ActivateCertificate":
						context.flow.digitalCertificateRequired = true;
						context.flow.webDomain = argumentValues[1];
						break;
					case "Macro_MainBrowser_GetAttribute":
						toAppend.steps.push({ stepType: "BrowserAction", action: "GetElementAttributeValue", variables: [ argumentValues[1], argumentValues[2], argumentValues[0] ], description: context.FormatSourceRefAndDescription(expression[0]), timeout: argumentValues.catchTimeout && !Number.isNaN(+argumentValues.catchTimeout[0]) ? +argumentValues.catchTimeout[0] : 10, catchTimeout: true });
						break;
					case "Macro_MainBrowser_GetInnerHTML":
						toAppend.steps.push({ stepType: "BrowserAction", action: "GetHTMLCodeByLocator", variables: [ argumentValues[1], argumentValues[0] ], description: context.FormatSourceRefAndDescription(expression[0]), timeout: argumentValues.catchTimeout && !Number.isNaN(+argumentValues.catchTimeout[0]) ? +argumentValues.catchTimeout[0] : 10, catchTimeout: true });
						break;
					case "Macro_MainBrowser_Navigate":
						toAppend.steps.push({ stepType: "BrowserAction", action: "NavigateToUrl", variables: [ argumentValues[0] ], description: context.FormatSourceRefAndDescription(expression[0]) });
						break;
					case "Macro_MainBrowser_PrimaryClick":
						toAppend.steps.push(Object.assign({ stepType: "BrowserAction", action: "PrimaryClick", variables: [ argumentValues[0] ], description: context.FormatSourceRefAndDescription(expression[0]) }, argumentValues.catchTimeout && { timeout: !Number.isNaN(+argumentValues.catchTimeout[0]) ? +argumentValues.catchTimeout[0] : 10000, catchTimeout: true }, argumentValues.catchException && { catchException: true }));
						break;
					case "Macro_MainBrowser_SaveAs":
						toAppend.steps.push({ stepType: "BrowserAction", action: "SaveAs", variables: [ argumentValues[0], argumentValues[1], argumentValues[2], argumentValues[3], argumentValues[4] ], timeout: 20000, description: context.FormatSourceRefAndDescription(expression[0]), disablePause: true });
						break;
					case "Macro_MainBrowser_Write":
						toAppend.steps.push({ stepType: "BrowserAction", action: "TypeInTextBox", variables: [ argumentValues[0], argumentValues[1] ], description: context.FormatSourceRefAndDescription(expression[0]) });
						break;
					case "Macro_JavaAccessBridge_SetStatus":
						toAppend.steps.push({ stepType: "JavaAction", action: "SetAccessBridgeStatus", variables: [ argumentValues[0] ], description: context.FormatSourceRefAndDescription(expression[0]) });
						break;
					case "Macro_JavaAccessBridge_GetStatus":
						toAppend.steps.push({ stepType: "JavaAction", action: "GetAccessBridgeStatus", variables: [ argumentValues[0] ], description: context.FormatSourceRefAndDescription(expression[0]) });
						break;
					case "Macro_JavaAccessBridge_EnumerateJVMs":
						toAppend.steps.push({ stepType: "JavaAction", action: "EnumerateVirtualMachines", variables: [ argumentValues[0] ], description: context.FormatSourceRefAndDescription(expression[0]) });
						break;
					case "Macro_JavaAccessBridge_EnumerateWindows":
						toAppend.steps.push({ stepType: "JavaAction", action: "EnumerateWindows", variables: [ argumentValues[0], argumentValues[1] ], description: context.FormatSourceRefAndDescription(expression[0]) });
						break;
					case "Macro_SuccessfulIterations":
						toAppend.steps.push({ stepType: "VariableControl", action: "NotifySuccessfulIterations", variables: [ argumentValues[0], argumentValues[1] ], description: context.FormatSourceRefAndDescription(expression[0]) });
						break;
					case "Macro_FailedIterations":
						toAppend.steps.push({ stepType: "VariableControl", action: "NotifyFailedIterations", variables: [ argumentValues[0], argumentValues[1] ], description: context.FormatSourceRefAndDescription(expression[0]) });
						break;
					case "Macro_SendVirtualKeyCodes":
						toAppend.steps.push({ stepType: "DesktopAction", action: "TypeVirtualKeyCodes", variables: [ "", argumentValues.join(","), "," ], description: context.FormatSourceRefAndDescription(expression[0]), disablePause: true });
						break;
					case "Macro_TypeText":
						toAppend.steps.push({ stepType: "DesktopAction", action: "TypeText", variables: [ "", argumentValues[0] ], description: context.FormatSourceRefAndDescription(expression[0]), disablePause: true });
						break;
					case "Macro_EnumerateProcesses":
						toAppend.steps.push({ stepType: "DesktopAction", action: "EnumerateProcesses", variables: [ argumentValues[0] ], description: context.FormatSourceRefAndDescription(expression[0]) });
						break;
					case "Macro_Count":
						toAppend.steps.push({ stepType: "JSONOperation", action: "Count", variables: [ argumentValues[0], argumentValues[1] ], description: context.FormatSourceRefAndDescription(expression[0]) });
						break;
					case "Macro_Substring":
						toAppend.steps.push({ stepType: "VariableOperation", action: "Substring", variables: [ argumentValues[0], argumentValues[1], argumentValues[2], argumentValues[3] ] });
						break;
					case "Macro_RawBlock":
						toAppend.steps.push(Object.assign({ stepType: argumentValues[0], action: argumentValues[1], variables: argumentValues.slice(2), description: context.FormatSourceRefAndDescription(expression[0]) }, argumentValues.timeout && !Number.isNaN(+argumentValues.timeout[0]) ? { timeout: +argumentValues.timeout[0] } : undefined, argumentValues.catchTimeout && !Number.isNaN(+argumentValues.catchTimeout[0]) ? { timeout: +argumentValues.catchTimeout[0], catchTimeout: true } : undefined, argumentValues.disableInteractionDetection && argumentValues.disableInteractionDetection[0] === 1 ? { disablePause: true } : undefined));
						break;
					case "Macro_Description":
						context.CurrentScope().description = argumentValues[0];
						break;
					case "Macro_RawBlockJson_Input": {
						try {
							const json = JSON.parse(argumentValues[0]);
							if (Array.isArray(json)) {
								json.forEach(elem => context.flow.flowInputs.push(elem));
							} else {
								context.flow.flowInputs.push(json);
							}
						} catch (err) {
							context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Not a valid JSON value", err.message, argumentValues[0] ]);
						}
					} break;
					case "Macro_RawBlockJson_Step": {
						try {
							const json = JSON.parse(argumentValues[0]);
							if (Array.isArray(json)) {
								json.forEach(elem => toAppend.steps.push(elem));
							} else {
								toAppend.steps.push(json);
							}
						} catch (err) {
							context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Not a valid JSON value", err.message, argumentValues[0] ]);
						}
					} break;
					case "Macro_RawBlockJson_Variable": {
						try {
							const json = JSON.parse(argumentValues[0]);
							if (Array.isArray(json)) {
								json.forEach(elem => context.flow.variables.push(elem));
							} else {
								context.flow.variables.push(json);
							}
						} catch (err) {
							context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Not a valid JSON value", err.message, argumentValues[0] ]);
						}
					} break;
					case "Macro_Breakpoint": {
						try {
							const random = Math.random().toString(36).substring(2);
							const variable = context.RequestTempVariable(expression[0]);
							toAppend.steps.push({ stepType: "VariableOperation", action: "Set", variables: [ variable, "" ], description: context.FormatSourceRefAndDescription(expression[0]) });
							toAppend.steps.push({ stepType: "VariableControl", action: "Sleep", key: "bploop-" + random, variables: [ 0 ], description: context.FormatSourceRefAndDescription(expression[0]) });
							const { newValuesList, toAppend: newToAppend } = expression[1] ? context.BuildFromExpressionNode(expression[1]) : { newValuesList: [ { type: "string", value: "", toFree: false } ], toAppend: { steps: [] } };
							if (newValuesList.length > 1) {
								context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Grouper has more than one expression, excess expressions will be ignored" ]);
							}
							toAppend.steps.push(...newToAppend.steps);
							const variable2 = context.RequestTempVariable(expression[0]);
							toAppend.steps.push({ stepType: "JSONOperation", action: "GetValue", variables: [ variable, variable, "variable", "$" ], description: context.FormatSourceRefAndDescription(expression[0]) });
							toAppend.steps.push({ stepType: "VariableOperation", action: "Concat", variables: [ variable2, "ok;continue;stop;textInput@variable/^\\$\\w+(?:\\['[^']*'\\])?$/:variable=", variable ], description: context.FormatSourceRefAndDescription(expression[0]) });
							toAppend.steps.push({ stepType: "VariableControl", action: "ShowWindow", variables: [ "Breakpoint at " + context.FormatSourceRefAndDescription(expression[0]), newValuesList[0].value, variable2, variable ], description: context.FormatSourceRefAndDescription(expression[0]), timeout: 999999999, catchTimeout: true, disablePause: true });
							toAppend.steps.push({ stepType: "VariableOperation", action: "ElementAt", variables: [ variable2, variable, ";", 0 ], description: context.FormatSourceRefAndDescription(expression[0]) });
							toAppend.steps.push({ stepType: "VariableControl", action: "BranchEqual", variables: [ variable2, "STOP", "bpstop-" + random ], description: context.FormatSourceRefAndDescription(expression[0]) });
							toAppend.steps.push({ stepType: "VariableControl", action: "BranchEqual", variables: [ variable2, "CANCEL", "bpcancel-" + random ], description: context.FormatSourceRefAndDescription(expression[0]) });
							toAppend.steps.push({ stepType: "VariableControl", action: "BranchEqual", variables: [ variable2, "CONTINUE", "bpcontinue-" + random ], description: context.FormatSourceRefAndDescription(expression[0]) });
							toAppend.steps.push({ stepType: "VariableOperation", action: "Length", variables: [ variable2, variable ], description: context.FormatSourceRefAndDescription(expression[0]) });
							toAppend.steps.push({ stepType: "VariableOperation", action: "Substring", variables: [ variable, variable, 3, variable2 ], description: context.FormatSourceRefAndDescription(expression[0]) });
							toAppend.steps.push({ stepType: "JSONOperation", action: "GetValue", variables: [ variable2, variable, "variable", "" ], description: context.FormatSourceRefAndDescription(expression[0]) });
							const variable3 = context.RequestTempVariable(expression[0]);
							toAppend.steps.push({ stepType: "VariableOperation", action: "MatchGroup", variables: [ variable3, "^(\\$\\w+)(?:\\['([^']+)'\\])?", variable2, 2 ], description: context.FormatSourceRefAndDescription(expression[0]) });
							toAppend.steps.push({ stepType: "VariableOperation", action: "MatchGroup", variables: [ variable2, "^(\\$\\w+)(?:\\['([^']+)'\\])?", variable2, 1 ], description: context.FormatSourceRefAndDescription(expression[0]) });
							toAppend.steps.push({ stepType: "VariablePointer", action: "PointerToVariable", variables: [ variable2, variable2 ], description: context.FormatSourceRefAndDescription(expression[0]) });
							toAppend.steps.push({ stepType: "VariableControl", action: "BranchEqual", variables: [ variable3, "", "bpshow-" + random ], description: context.FormatSourceRefAndDescription(expression[0]) });
							toAppend.steps.push({ stepType: "JSONOperation", action: "GetValue", variables: [ variable2, variable2, variable3, "(no value)" ], description: context.FormatSourceRefAndDescription(expression[0]) });
							toAppend.steps.push({ stepType: "VariableControl", action: "Sleep", key: "bpshow-" + random, variables: [ 0 ], description: context.FormatSourceRefAndDescription(expression[0]) });
							toAppend.steps.push({ stepType: "VariableControl", action: "ShowWindow", variables: [ "Processing", variable2, "", "" ], description: context.FormatSourceRefAndDescription(expression[0]), timeout: 999999999, catchTimeout: true, disablePause: true });
							toAppend.steps.push({ stepType: "VariableControl", action: "BranchEqual", variables: [ "", "", "bploop-" + random ], description: context.FormatSourceRefAndDescription(expression[0]) });
							toAppend.steps.push({ stepType: "VariableControl", action: "Sleep", key: "bpstop-" + random, variables: [ 0 ], description: context.FormatSourceRefAndDescription(expression[0]) });
							toAppend.steps.push({ stepType: "VariableControl", action: "EndWithFailure", variables: [], description: context.FormatSourceRefAndDescription(expression[0]) });
							toAppend.steps.push({ stepType: "VariableControl", action: "Sleep", key: "bpcancel-" + random, variables: [ 0 ], description: context.FormatSourceRefAndDescription(expression[0]) });
							toAppend.steps.push({ stepType: "VariableControl", action: "End", variables: [], description: context.FormatSourceRefAndDescription(expression[0]) });
							toAppend.steps.push({ stepType: "VariableControl", action: "Sleep", key: "bpcontinue-" + random, variables: [ 0 ], description: context.FormatSourceRefAndDescription(expression[0]) });
							context.FreeTempVariable(variable3);
							context.FreeTempVariable(variable2);
							context.FreeTempVariable(variable);
							if (newValuesList[0]?.toFree) {
								context.FreeTempVariable(newValuesList[0].value);
							}
							if (variable.toFree) {
								context.FreeTempVariable(variable.value);
							}
						} catch (err) {
							context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), err.message, expression, err ]);
						}
					} break;
					default:
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Unknown macro", expression ]);
				}
				tempVariables.forEach(variable => context.FreeTempVariable(variable));
			} else if (expression[0].type === "control") {
				switch (expression[0].value) {
					case "if": {
						if (expression[0].condition.expression.length !== 1 || expression[0].condition.expression[0].length !== 1) {
							context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Only one-token expressions can be used as conditions", expression[0].condition.expression ]);
							break;
						}
						const { newValuesList, toAppend: newToAppend } = context.BuildFromExpressionNode(expression[0].condition.expression[0][0]);
						toAppend.steps.push(...newToAppend.steps);
						if (newValuesList.length > 1) {
							context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Grouper has more than one expression, excess expressions will be ignored" ]);
						}
						if ([ "number", "string" ].includes(newValuesList[0].type)) {
							if (newValuesList[0].value != 0) {
								const { toAppend: leftNewToAppend } = context.BuildFromExpressionList(expression[0].then.expression);
								toAppend.steps.push(...leftNewToAppend.steps);
							} else {
								const { toAppend: rightNewToAppend } = context.BuildFromExpressionList(expression[0].else.expression);
								toAppend.steps.push(...rightNewToAppend.steps);
							}
						} else {
							const random = Math.random().toString(36).substring(2);
							toAppend.steps.push({ stepType: "VariableControl", action: "BranchEqual", variables: [ newValuesList[0].value, "0", "else-" + random ], description: context.FormatSourceRefAndDescription(expression[0]) });
							if (newValuesList[0].toFree) {
								context.FreeTempVariable(newValuesList[0].value);
							}
							const { toAppend: leftNewToAppend } = context.BuildFromExpressionList(expression[0].then.expression);
							toAppend.steps.push(...leftNewToAppend.steps);
							toAppend.steps.push({ stepType: "VariableControl", action: "BranchEqual", variables: [ "", "", "end-" + random ], description: context.FormatSourceRefAndDescription(expression[0]) });
							toAppend.steps.push({ stepType: "VariableControl", action: "Sleep", key: "else-" + random, variables: [ 0 ], description: context.FormatSourceRefAndDescription(expression[0]) });
							const { toAppend: rightNewToAppend } = context.BuildFromExpressionList(expression[0].else.expression);
							toAppend.steps.push(...rightNewToAppend.steps);
							toAppend.steps.push({ stepType: "VariableControl", action: "Sleep", key: "end-" + random, variables: [ 0 ], description: context.FormatSourceRefAndDescription(expression[0]) });
						}
					} break;
					case "while": {
						if (expression[0].condition.expression.length !== 1 || expression[0].condition.expression[0].length !== 1) {
							context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Only one-token expressions can be used as conditions", expression[0].condition.expression ]);
							break;
						}
						const conditionIsVariable = expression[0].condition.expression[0][0].type === "variable";
						const random = Math.random().toString(36).substring(2);
						context.loopControlPoints.push({ break: "end-" + random, continue: conditionIsVariable ? "while-" + random : "condition-" + random });
						if (!conditionIsVariable) {
							toAppend.steps.push({ stepType: "VariableControl", action: "Sleep", key: "condition-" + random, variables: [ 0 ], description: context.FormatSourceRefAndDescription(expression[0]) });
						}
						const { newValuesList, toAppend: conditionNewToAppend } = conditionIsVariable ? { newValuesList: [ expression[0].condition.expression[0][0] ], toAppend: { steps: [] } } : context.BuildFromExpressionNode(expression[0].condition.expression[0][0]);
						toAppend.steps.push(...conditionNewToAppend.steps);
						if (newValuesList.length > 1) {
							context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Grouper has more than one expression, excess expressions will be ignored" ]);
						}
						toAppend.steps.push({ stepType: "VariableControl", action: "BranchEqual", key: "while-" + random, variables: [ newValuesList[0].value, "0", "end-" + random ], description: context.FormatSourceRefAndDescription(expression[0]) });
						const { toAppend: newToAppend } = context.BuildFromExpressionList(expression[0].body.expression);
						toAppend.steps.push(...newToAppend.steps);
						toAppend.steps.push({ stepType: "VariableControl", action: "BranchEqual", variables: [ "", "", (conditionIsVariable ? "while-" : "condition-") + random ], description: context.FormatSourceRefAndDescription(expression[0]) });
						toAppend.steps.push({ stepType: "VariableControl", action: "Sleep", key: "end-" + random, variables: [ 0 ], description: context.FormatSourceRefAndDescription(expression[0]) });
						context.loopControlPoints.pop();
						if (newValuesList[0].toFree) {
							context.FreeTempVariable(newValuesList[0].value);
						}
					} break;
					case "foreach": {
						if (expression[0].iteratingVariable.expression.length !== 1 || expression[0].iteratingVariable.expression[0].length !== 1 || expression[0].iteratingVariable.expression[0][0].type !== "variable") {
							context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Only variables can be used as iterating variables", expression[0].iteratingVariable.expression ]);
							break;
						}
						if (expression[0].iteratingList.expression.length !== 1 || expression[0].iteratingList.expression[0].length !== 1/* || expression[0].iteratingList.expression[0][0].type !== "variable"*/) {
							context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Only variables can be used as iterating lists", expression[0].iteratingList.expression ]);
							break;
						}
						const { newValuesList, toAppend: rightNewToAppend } = context.BuildFromExpressionNode(expression[0].iteratingList.expression[0][0]);
						toAppend.steps.push(...rightNewToAppend.steps);
						if (newValuesList.length > 1) {
							context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Grouper has more than one expression, excess expressions will be ignored" ]);
						}
						const variable = context.RequestTempVariable(expression[0]);
						const iterator = context.RequestTempVariable(expression[0]);
						const random = Math.random().toString(36).substring(2);
						context.loopControlPoints.push({ break: "end-" + random, continue: "available-" + random });
						toAppend.steps.push({ stepType: "IteratorOperation", action: "CreateStreamIterator", variables: [ iterator, newValuesList[0].value ], description: context.FormatSourceRefAndDescription(expression[0]) });
						toAppend.steps.push({ stepType: "IteratorOperation", action: "Available", key: "available-" + random, variables: [ variable, iterator ], description: context.FormatSourceRefAndDescription(expression[0]) });
						toAppend.steps.push({ stepType: "VariableControl", action: "BranchEqual", variables: [ variable, 0, "end-" + random ], description: context.FormatSourceRefAndDescription(expression[0]) });
						toAppend.steps.push({ stepType: "IteratorOperation", action: "Read", variables: [ expression[0].iteratingVariable.expression[0][0].value, iterator ], description: context.FormatSourceRefAndDescription(expression[0]) });
						const { toAppend: newToAppend } = context.BuildFromExpressionList(expression[0].body.expression);
						toAppend.steps.push(...newToAppend.steps);
						toAppend.steps.push({ stepType: "VariableControl", action: "BranchEqual", variables: [ "", "", "available-" + random ], description: context.FormatSourceRefAndDescription(expression[0]) });
						toAppend.steps.push({ stepType: "VariableControl", action: "Sleep", key: "end-" + random, variables: [ 0 ], description: context.FormatSourceRefAndDescription(expression[0]) });
						context.loopControlPoints.pop();
						context.FreeTempVariable(iterator);
						context.FreeTempVariable(variable);
						if (newValuesList[0].toFree) {
							context.FreeTempVariable(newValuesList[0].value);
						}
					} break;
					case "break":
						if (context.loopControlPoints.length) {
							toAppend.steps.push({ stepType: "VariableControl", action: "BranchEqual", variables: [ "", "", context.loopControlPoints[context.loopControlPoints.length - 1].break ], description: context.FormatSourceRefAndDescription(expression[0]) });
						} else {
							context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Not in a loop" ]);
						}
						break;
					case "continue":
						if (context.loopControlPoints.length) {
							toAppend.steps.push({ stepType: "VariableControl", action: "BranchEqual", variables: [ "", "", context.loopControlPoints[context.loopControlPoints.length - 1].continue ], description: context.FormatSourceRefAndDescription(expression[0]) });
						} else {
							context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Not in a loop" ]);
						}
						break;
					case "try": {
						if (expression[0].catchVariable.expression.length !== 1 || expression[0].catchVariable.expression[0].length !== 1 || expression[0].catchVariable.expression[0][0].type !== "variable") {
							context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Only variables can be used as catch variables" ]);
							break;
						}
						const random = Math.random().toString(36).substring(2);
						toAppend.steps.push({ stepType: "VariableControl", action: "Trap", variables: [ expression[0].catchVariable.expression[0][0].value, "Exception", "catch-" + random ] });
						const { toAppend: tryNewToAppend } = context.BuildFromExpressionList(expression[0].try.expression);
						toAppend.steps.push(...tryNewToAppend.steps);
						toAppend.steps.push({ stepType: "VariableControl", action: "Trap", variables: [ expression[0].catchVariable.expression[0][0].value, "Exception", "" ] });
						toAppend.steps.push({ stepType: "VariableControl", action: "BranchEqual", variables: [ "", "", "finally-" + random ] });
						toAppend.steps.push({ stepType: "VariableControl", action: "Sleep", key: "catch-" + random, variables: [ 0 ] });
						toAppend.steps.push({ stepType: "VariableControl", action: "Trap", variables: [ expression[0].catchVariable.expression[0][0].value, "Exception", "" ] });
						const { toAppend: catchNewToAppend } = context.BuildFromExpressionList(expression[0].catch.expression);
						toAppend.steps.push(...catchNewToAppend.steps);
						toAppend.steps.push({ stepType: "VariableControl", action: "Sleep", key: "finally-" + random, variables: [ 0 ] });
						const { toAppend: finallyNewToAppend } = context.BuildFromExpressionList(expression[0].finally.expression);
						toAppend.steps.push(...finallyNewToAppend.steps);
					} break;
					case "return": {
						const { newValuesList, toAppend: newToAppend } = context.BuildFromExpressionNode({
							index: expression[0].index,
							line: expression[0].line,
							column: expression[0].column,
							file: expression[0].file,
							type: "virtualGrouper",
							value: "()",
							scope: { owner: "virtualGrouper", status: { type: "code" }, variables: {}, expression: [ expression.slice(1) ] },
						});
						toAppend.steps.push(...newToAppend.steps);
						toAppend.steps.push({ stepType: "VariableControl", action: "Return", variables: [ newValuesList[0].value ], description: context.FormatSourceRefAndDescription(expression[0]) });
						if (newValuesList[0].toFree) {
							context.FreeTempVariable(newValuesList[0].value);
						}
					} break;
					case "switch": {
						if (expression[0].selector.expression.length !== 1 || expression[0].selector.expression[0].length !== 1) {
							context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Only one-token expressions can be used as selectors", expression[0].selector.expression ]);
							break;
						}
						if (expression[0].cases.some(caseData => caseData.case.expression.length !== 1 || caseData.case.expression[0].length !== 1)) {
							context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Only one-token expressions can be used as cases", expression[0].selector.expression ]);
						}
						const { newValuesList, toAppend: newToAppend } = context.BuildFromExpressionNode(expression[0].selector.expression[0][0]);
						toAppend.steps.push(...newToAppend.steps);
						if (newValuesList.length > 1) {
							context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Grouper has more than one expression, excess expressions will be ignored" ]);
						}
						if ([ "number", "string" ].includes(newValuesList[0].type)) {
							const foundCase = expression[0].cases.find(caseData => [ "number", "string" ].includes(caseData.case.expression[0][0].type) && caseData.case.expression[0][0].value.toString() === newValuesList[0].value.toString());
							if (foundCase) {
								const { toAppend: leftNewToAppend } = context.BuildFromExpressionList(foundCase.body.expression);
								toAppend.steps.push(...leftNewToAppend.steps);
								break;
							}
						}
						const mapperVariable = context.RequestTempVariable(expression[0]);
						const selectorVariable = context.RequestTempVariable(expression[0]);
						const keyVariable = context.RequestTempVariable(expression[0]);
						const mapperData = {};
						const random = Math.random().toString(36).substring(2);
						let toAppendNext = { steps: [] };
						for (const caseIndex in expression[0].cases) {
							const caseData = expression[0].cases[caseIndex];
							const key = "case-" + random + "-" + caseIndex;
							if ([ "number", "string" ].includes(caseData.case.expression[0][0].type)) {
								mapperData["case-" + caseData.case.expression[0][0].value] ??= key;
							} else {
								const { newValuesList: caseNewValuesList, toAppend: leftNewToAppend } = context.BuildFromExpressionNode(caseData.case.expression[0][0]);
								toAppendNext.steps.push(...leftNewToAppend.steps);
								const auxVariable = context.RequestTempVariable(caseData.case.expression[0][0]);
								toAppendNext.steps.push({ stepType: "VariableOperation", action: "Concat", variables: [ keyVariable, "case-", caseNewValuesList[0].value ], description: context.FormatSourceRefAndDescription(caseData.case.expression[0][0]) });
								toAppendNext.steps.push({ stepType: "JSONOperation", action: "GetValue", variables: [ selectorVariable, mapperVariable, keyVariable, "" ], description: context.FormatSourceRefAndDescription(caseData.case.expression[0][0]) });
								toAppendNext.steps.push({ stepType: "JSONOperation", action: "GetValue", variables: [ auxVariable, mapperVariable, keyVariable, "0" ], description: context.FormatSourceRefAndDescription(caseData.case.expression[0][0]) });
								const random2 = Math.random().toString(36).substring(2);
								toAppendNext.steps.push({ stepType: "VariableControl", action: "BranchEqual", variables: [ selectorVariable, auxVariable, "caseDefSkip-" + random2 ], description: context.FormatSourceRefAndDescription(caseData.case.expression[0][0]) });
								toAppendNext.steps.push({ stepType: "JSONOperation", action: "SetValue", variables: [ mapperVariable, mapperVariable, keyVariable, key ], description: context.FormatSourceRefAndDescription(caseData.case.expression[0][0]) });
								toAppendNext.steps.push({ stepType: "VariableControl", action: "Sleep", key: "caseDefSkip-" + random2, variables: [ 0 ], description: context.FormatSourceRefAndDescription(caseData.case.expression[0][0]) });
								context.FreeTempVariable(auxVariable);
								if (caseNewValuesList[0].toFree) {
									context.FreeTempVariable(caseNewValuesList[0].value);
								}
							}
						}
						toAppend.steps.push({ stepType: "VariableOperation", action: "Set", variables: [ mapperVariable, JSON.stringify(mapperData) ], description: context.FormatSourceRefAndDescription(expression[0]) });
						toAppend.steps.push(...toAppendNext.steps);
						toAppend.steps.push({ stepType: "VariableOperation", action: "Concat", variables: [ keyVariable, "case-", newValuesList[0].value ] });
						toAppend.steps.push({ stepType: "JSONOperation", action: "GetValue", variables: [ selectorVariable, mapperVariable, keyVariable, "" ], description: context.FormatSourceRefAndDescription(expression[0]) });
						toAppend.steps.push({ stepType: "JSONOperation", action: "GetValue", variables: [ mapperVariable, mapperVariable, keyVariable, "0" ], description: context.FormatSourceRefAndDescription(expression[0]) });
						toAppend.steps.push({ stepType: "VariableControl", action: "BranchNotEqual", variables: [ selectorVariable, mapperVariable, "end-" + random ], description: context.FormatSourceRefAndDescription(expression[0]) });
						toAppend.steps.push({ stepType: "VariableControl", action: "BranchEqual", variables: [ "", "", selectorVariable ], description: context.FormatSourceRefAndDescription(expression[0]) });
						for (const caseIndex in expression[0].cases) {
							const caseData = expression[0].cases[caseIndex];
							const key = "case-" + random + "-" + caseIndex;
							toAppend.steps.push({ stepType: "VariableControl", action: "Sleep", key, variables: [ 0 ], description: context.FormatSourceRefAndDescription(caseData.case.expression[0][0]) });
							const { toAppend: rightNewToAppend } = context.BuildFromExpressionList(caseData.body.expression);
							toAppend.steps.push(...rightNewToAppend.steps);
							toAppend.steps.push({ stepType: "VariableControl", action: "BranchEqual", variables: [ "", "", "end-" + random ], description: context.FormatSourceRefAndDescription(caseData.case.expression[0][0]) });
						}
						toAppend.steps.push({ stepType: "VariableControl", action: "Sleep", key: "end-" + random, variables: [ 0 ], description: context.FormatSourceRefAndDescription(expression[0]) });
						context.FreeTempVariable(keyVariable);
						context.FreeTempVariable(selectorVariable);
						context.FreeTempVariable(mapperVariable);
						if (newValuesList[0].toFree) {
							context.FreeTempVariable(newValuesList[0].value);
						}
					} break;
					default:
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Unknown control", expression ]);
				}
			} else if (expression.length === 2 && expression[0].type === "function" && expression[1].type === "grouper") {
				let tempVariables = [];
				let expressionArguments = expression.slice(1);
				const args = expression[1].scope.expression.reduce((accu, curr) => {
					if (curr.length === 0) {
						return Object.assign(accu, { next: "" });
					}
					if (curr[0].type === "namedParameter") {
						accu.next = curr[0].value;
					}
					const { newValuesList: newValue, toAppend: newToAppend } = context.BuildFromExpressionNode({
						index: curr[0].index,
						line: curr[0].line,
						column: curr[0].column,
						file: curr[0].file,
						type: "virtualGrouper",
						value: "()",
						scope: { owner: "virtualGrouper", status: { type: "code" }, variables: {}, expression: [ curr.slice(curr[0].type === "namedParameter" ? 1 : 0) ] },
					});
					if (newValue.length > 1) {
						context.errors.push([ context.FormatSourceRefAndDescription(curr), "Grouper has more than one expression, excess expressions will be ignored" ]);
					}
					toAppend.steps.push(...newToAppend.steps);
					(accu.next ? (accu.list[accu.next] ??= []) : accu.list).push(newValue[0]);
					if (newValue[0]?.toFree) {
						tempVariables.push(newValue[0].value);
					}
					return Object.assign(accu, { next: "" });
				}, { list: [], next: "" }).list;
				let returnValue = {};
				const newToAppend = expression[0].compile(context, expression, args, returnValue);
				toAppend.steps.push(...newToAppend.steps);
				tempVariables.forEach(variable => context.FreeTempVariable(variable));
				if (returnValue.type === "variable") {
					context.FreeTempVariable(returnValue.value);
				}
			} else if (expression.length === 2 && expression[0].type === "functionDef") {
				const random = Math.random().toString(36).substring(2);
				toAppend.steps.push({ stepType: "VariableControl", action: "BranchEqual", variables: [ "", "", "end-" + random ], description: context.FormatSourceRefAndDescription(expression[0]) });
				toAppend.steps.push({ stepType: "VariableControl", action: "Sleep", key: "fn_" + expression[0].value, variables: [ 0 ], description: context.FormatSourceRefAndDescription(expression[0]) });
				// VariableControl.GetArgument(variable, index)
				// TODO TODO TODO arguments, function body
				/*
				console.log(expression[0].value);
				console.log(expression[1]);
				console.log(expression[0].body.expression);
				*/
				const head = expression[1].scope.expression.filter(subExpression => subExpression.length);
				if (head.some(subExpression => subExpression.length !== 1 || subExpression[0].type !== "variable")) {
					context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function definitions can only have variables as arguments" ]);
				}
				if (context.definedIdentifiers[expression[0].value]) {
					context.definedIdentifiers[expression[0].value].value.header = "(" + head.map(subExpression => subExpression[0].value).join(", ") + "): any";
				}
				toAppend.steps.push(...head.map((subExpression, index) => ({ stepType: "VariableControl", action: "GetArgument", variables: [ subExpression[0].value, index ], description: context.FormatSourceRefAndDescription(subExpression[0]) })));
				const { toAppend: newToAppend } = context.BuildFromExpressionList(expression[0].body.expression);
				toAppend.steps.push(...newToAppend.steps);
				toAppend.steps.push({ stepType: "VariableControl", action: "Return", variables: [ "" ], description: context.FormatSourceRefAndDescription(expression[0]) });
				toAppend.steps.push({ stepType: "VariableControl", action: "Sleep", key: "end-" + random, variables: [ 0 ], description: context.FormatSourceRefAndDescription(expression[0]) });
			} else if (expression.length === 1 && expression[0].type === "variable") {
				// TODO console.log("Variable DETECTED");
			} else {
				context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Unprocessable expression", expression ]);
			}
			return { toAppend };
			//BuildFromExpression end
		};
		context.BuildFromExpressionList = expressionList => {
			let toAppend = { steps: [] };
			for (let i = 0; i < expressionList.length; i++) {
				if (expressionList[i].length) {
					const { toAppend: newToAppend } = context.BuildFromExpression(expressionList[i]);
					toAppend.steps.push(...newToAppend.steps);
				}
			}
			return { toAppend };
		};
		context.EnumerateExternals = () => {
			return [ ...new Set([ ...Object.keys(context.externals), ...Object.keys(parentContext?.EnumerateExternals?.() ?? {}), ...Object.keys(parentContext?.externals ?? {}) ]) ];
		};
		context.ImportExternal = what => {
			return context.externals[what] ?? parentContext?.ImportExternal?.(what) ?? parentContext?.externals?.[what];
		};
		context.Compile = () => {
			let { toAppend } = context.BuildFromExpressionList(context.scopes[0].expression);
			context.flow.steps.push(...toAppend.steps);
		};
	}, [
		{ key: "space", regex: String.raw`\s+`, style: "color:white" },
		{ key: "flowInformation", regex: String.raw`(?<=^)/\*\*.*?\*/`, function: ({ match, index, line, column, file, input, groups, context, parentContext }) => {
			const result = flowMetadata(match[0], { startIndex: index, startLine: line, startColumn: column, srcFile: file }, context);
			context.flow._id = result[1].flowId;
			context.flow.group = result[1].flowGroup;
			context.flow.status = result[1].status;
			context.flow.name = result[1].name;
			context.flow.description = result[1].description;
			context.flow.digitalCertificateRequired = result[1].digitalCertificateRequired;
			context.flow.outputDirectoryVariable = result[1].outputDirectoryVariable;
			context.flow.userLogFileVariableKey = result[1].userLogFileVariableKey;
			context.flow.accessControl = result[1].accessControl;
			context.flow.instructionsURL = result[1].instructionsURL;
			context.flow.templateURL = result[1].templateURL;
			context.flow.timeSavedRatio = result[1].timeSavedRatio;
			context.flow.usageRatio = result[1].usageRatio;
			context.flow.autoRepeatRowErrorsLimit = result[1].autoRepeatRowErrorsLimit;
			context.flow.allowMouseInput = result[1].allowMouseInput;
			context.flow.allowKeyboardInput = result[1].allowKeyboardInput;
			return result[0];
		} },
		{ key: "comment", regex: String.raw`//[^\n]*\n|/\*.*?\*/`, style: "color:silver" },
		{ key: "number", regex: String.raw`\d+(?:\.\d+)?(?:[eE][+-]?\d+)?(?=\b)(?!\.)`, function: ({ match, index, line, column, file, input, groups, context, parentContext }) => {
			context.CurrentScopePosition().push({ index, line, column, file, type: "number", value: match[0] });
			return buildSpan(index, "color:lime", match[0]);
		} },
		{ key: "string", regex: String.raw`'(?:[^'\\]|\\.)*'`, function: ({ match, index, line, column, file, input, groups, context, parentContext }) => {
			context.CurrentScopePosition().push({ index, line, column, file, type: "string", value: match[0].substring(1, match[0].length - 1).replaceAll(/\\./g, f => ({ "\\n": "\n", "\\r": "\r", "\\t": "\t" })[f] ?? f[1]) });
			return buildSpan(index, "color:yellow", match[0]);
		} },
		{ key: "template", regex: String.raw`"(?:[^"\\]|\\.)*"`, function: ({ match, index, line, column, file, input, groups, context, parentContext }) => {
			context.CurrentScopePosition().push({ index, line, column, file, type: "template", value: match[0].substring(1, match[0].length - 1).replaceAll(/\\./g, f => ({ "\\n": "\n", "\\r": "\r", "\\t": "\t" })[f] ?? f[1]) });
			return interpolateString(match[0], { startIndex: index, startLine: line, startColumn: column, srcFile: file }, context)[0];
		} },
		{ key: "namedParameter", regex: String.raw`[a-z][a-zA-Z0-9]*:`, function: ({ match, index, line, column, file, input, groups, context, parentContext }) => {
			context.CurrentScopePosition().push({ index, line, column, file, type: "namedParameter", value: match[0].substring(0, match[0].length - 1) });
			const parentScopeCurrentExpression = context.ParentScope()?.expression.slice(-1)[0];
			const parentScopePreviousToken = parentScopeCurrentExpression?.slice(-2, -1)[0];
			const thisName = match[0].substring(0, match[0].length - 1);
			if ((context.CurrentScopePosition().acceptedNamedParameters ?? []).includes(thisName) || parentScopePreviousToken?.type === "function" && (parentScopePreviousToken?.acceptedNamedParameters ?? []).includes(thisName)) {
				return buildSpan(index, "color:deeppink", match[0]);
			} else {
				return buildSpan(index, "color:gray", match[0]);
			}
		} },
		{ key: "keyword", regex: String.raw`[a-zA-Z_][a-zA-Z_0-9]*(?=\b)`, function: ({ match, index, line, column, file, input, groups, context, parentContext }) => {
			switch (match[0]) {
				case "var":
					if (context.CurrentScope().status.type === "code") {
						context.CurrentScope().status = { type: "varDef-keyword" };
						return buildSpan(index, "color:aqua", match[0]);
					} else {
						context.errors.push([ context.FormatSourceRefAndDescription({ index, line, column, file }), "Invalid keyword" ]);
						return buildSpan(index, "color:red", match[0]);
					}
					break;
				case "const":
					if (context.CurrentScope().status.type === "code") {
						context.CurrentScope().status = { type: "constDef-keyword" };
						return buildSpan(index, "color:aqua", match[0]);
					} else {
						context.errors.push([ context.FormatSourceRefAndDescription({ index, line, column, file }), "Invalid keyword" ]);
						return buildSpan(index, "color:red", match[0]);
					}
					break;
				case "import":
					if (context.CurrentScope().status.type === "code") {
						context.CurrentScope().status = { type: "import-keyword" };
						return buildSpan(index, "color:aqua", match[0]);
					} else {
						context.errors.push([ context.FormatSourceRefAndDescription({ index, line, column, file }), "Invalid keyword" ]);
						return buildSpan(index, "color:red", match[0]);
					}
					break;
				case "as":
					if (context.CurrentScope().status.type === "import-keyword" && context.CurrentScopePosition().length && context.CurrentScopePosition()[0].type === "string") {
						context.CurrentScope().status = { type: "import-as" };
						return buildSpan(index, "color:aqua", match[0]);
					} else {
						context.errors.push([ context.FormatSourceRefAndDescription({ index, line, column, file }), "Invalid keyword" ]);
						return buildSpan(index, "color:red", match[0]);
					}
					break;
				case "if":
					if (context.CurrentScope().status.type === "code") {
						context.CurrentScope().status = { type: "if" };
						context.CurrentScopePosition().push({
							index, line, column, file,
							type: "control",
							value: "if",
							condition: { owner: "control", status: { type: "code" }, variables: {}, expression: [ [] ], identifiers: {}, description: "" },
							then: { owner: "control", status: { type: "code" }, variables: {}, expression: [ [] ], identifiers: {}, description: "" },
							else: { owner: "control", status: { type: "code" }, variables: {}, expression: [ [] ], identifiers: {}, description: "" },
						});
						context.scopes.push(context.CurrentScopePosition().slice(-1)[0].condition);
						return buildSpan(index, "color:aqua", match[0]);
					} else {
						return buildSpan(index, "color:red", match[0]);
					}
					break;
				case "then":
					if (context.CurrentScope().status.type === "code" && context.ParentScope()?.status.type === "if") {
						context.scopes.pop();
						context.scopes.push(context.CurrentScopePosition().slice(-1)[0].then);
						return buildSpan(index, "color:aqua", match[0]);
					} else {
						return buildSpan(index, "color:red", match[0]);
					}
					break;
				case "else":
					if (context.CurrentScope().status.type === "code" && context.ParentScope()?.status.type === "if") {
						context.scopes.pop();
						context.scopes.push(context.CurrentScopePosition().slice(-1)[0].else);
						return buildSpan(index, "color:aqua", match[0]);
					} else {
						return buildSpan(index, "color:red", match[0]);
					}
					break;
				case "while":
					if (context.CurrentScope().status.type === "code") {
						context.CurrentScope().status = { type: "while" };
						context.CurrentScopePosition().push({
							index, line, column, file,
							type: "control",
							value: "while",
							condition: { owner: "control", status: { type: "code" }, variables: {}, expression: [ [] ], identifiers: {}, description: "" },
							body: { owner: "control", status: { type: "code" }, variables: {}, expression: [ [] ], identifiers: {}, description: "" },
						});
						context.scopes.push(context.CurrentScopePosition().slice(-1)[0].condition);
						return buildSpan(index, "color:aqua", match[0]);
					} else {
						return buildSpan(index, "color:red", match[0]);
					}
					break;
				case "foreach":
					if (context.CurrentScope().status.type === "code") {
						context.CurrentScope().status = { type: "foreach" };
						context.CurrentScopePosition().push({
							index, line, column, file,
							type: "control",
							value: "foreach",
							iteratingVariable: { owner: "control", status: { type: "code" }, variables: {}, expression: [ [] ], identifiers: {}, description: "" },
							iteratingList: { owner: "control", status: { type: "code" }, variables: {}, expression: [ [] ], identifiers: {}, description: "" },
							body: { owner: "control", status: { type: "code" }, variables: {}, expression: [ [] ], identifiers: {}, description: "" },
						});
						context.scopes.push(context.CurrentScopePosition().slice(-1)[0].iteratingVariable);
						return buildSpan(index, "color:aqua", match[0]);
					} else {
						return buildSpan(index, "color:red", match[0]);
					}
					break;
				case "break":
				case "continue":
					context.CurrentScopePosition().push({ index, line, column, file, type: "control", value: match[0] });
					return buildSpan(index, "color:aqua", match[0]);
					break;
				case "in":
					if (context.CurrentScope().status.type === "code" && context.ParentScope()?.status.type === "foreach") {
						context.scopes.pop();
						context.scopes.push(context.CurrentScopePosition().slice(-1)[0].iteratingList);
						return buildSpan(index, "color:aqua", match[0]);
					} else {
						return buildSpan(index, "color:red", match[0]);
					}
					break;
				case "do":
					if (context.CurrentScope().status.type === "code" && [ "while", "foreach" ].includes(context.ParentScope()?.status.type)) {
						context.scopes.pop();
						context.scopes.push(context.CurrentScopePosition().slice(-1)[0].body);
						return buildSpan(index, "color:aqua", match[0]);
					} else if (context.CurrentScope().status.type === "code" && context.ParentScope()?.status.type === "try") {
						context.scopes.pop();
						context.scopes.push(context.CurrentScopePosition().slice(-1)[0].catch);
						return buildSpan(index, "color:aqua", match[0]);
					} else if (context.CurrentScope().status.type === "code" && context.ParentScope()?.status.type === "switch") {
						const newBody = context.ParentScopePosition().slice(-1)[0].cases.slice(-1)[0].body
						context.scopes.pop();
						context.scopes.push(newBody);
						return buildSpan(index, "color:aqua", match[0]);
					} else {
						return buildSpan(index, "color:red", match[0]);
					}
				case "return":
					if (context.ParentScope()?.status.type === "functionDef-body") {
						context.CurrentScopePosition().push({ index, line, column, file, type: "control", value: "return" });
						return buildSpan(index, "color:aqua", match[0]);
					} else {
						return buildSpan(index, "color:red", match[0]);
					}
					break;
				case "end":
					if ([ "if", "while", "foreach", "functionDef-body", "try", "switch" ].includes(context.ParentScope()?.status.type)) {
						context.scopes.pop();
						return buildSpan(index, "color:aqua", match[0]);
					} else {
						return buildSpan(index, "color:red", match[0]);
					}
					break;
				case "try":
					if (context.CurrentScope().status.type === "code") {
						context.CurrentScope().status = { type: "try" };
						context.CurrentScopePosition().push({
							index, line, column, file,
							type: "control",
							value: "try",
							try: { owner: "control", status: { type: "code" }, variables: {}, expression: [ [] ], identifiers: {}, description: "" },
							catchVariable: { owner: "control", status: { type: "code" }, variables: {}, expression: [ [] ], identifiers: {}, description: "" },
							catch: { owner: "control", status: { type: "code" }, variables: {}, expression: [ [] ], identifiers: {}, description: "" },
							finally: { owner: "control", status: { type: "code" }, variables: {}, expression: [ [] ], identifiers: {}, description: "" },
						});
						context.scopes.push(context.CurrentScopePosition().slice(-1)[0].try);
						return buildSpan(index, "color:aqua", match[0]);
					} else {
						return buildSpan(index, "color:red", match[0]);
					}
					break;
				case "catch":
					if (context.CurrentScope().status.type === "code" && context.ParentScope()?.status.type === "try") {
						context.scopes.pop();
						context.scopes.push(context.CurrentScopePosition().slice(-1)[0].catchVariable);
						return buildSpan(index, "color:aqua", match[0]);
					} else {
						return buildSpan(index, "color:red", match[0]);
					}
					break;
				case "finally":
					if (context.CurrentScope().status.type === "code" && context.ParentScope()?.status.type === "try") {
						context.scopes.pop();
						context.scopes.push(context.CurrentScopePosition().slice(-1)[0].finally);
						return buildSpan(index, "color:aqua", match[0]);
					} else {
						return buildSpan(index, "color:red", match[0]);
					}
					break;
				case "function":
					if (context.CurrentScope().status.type === "code") {
						context.CurrentScope().status = { type: "functionDef-keyword" };
						context.CurrentScopePosition().push({
							index, line, column, file,
							type: "functionDef",
							value: "",
							body: { owner: "function", status: { type: "code" }, variables: {}, expression: [ [] ], identifiers: {}, description: "" },
						});
						return buildSpan(index, "color:aqua", match[0]);
					} else {
						return buildSpan(index, "color:red", match[0]);
					}
					break;
				case "switch":
					if (context.CurrentScope().status.type === "code") {
						context.CurrentScope().status = { type: "switch" };
						context.CurrentScopePosition().push({
							index, line, column, file,
							type: "control",
							value: "switch",
							selector: { owner: "control", status: { type: "code" }, variables: {}, expression: [ [] ], identifiers: {}, description: "" },
							cases: [],
						});
						context.scopes.push(context.CurrentScopePosition().slice(-1)[0].selector);
						return buildSpan(index, "color:aqua", match[0]);
					} else {
						return buildSpan(index, "color:red", match[0]);
					}
					break;
				case "case":
					if (context.CurrentScope().status.type === "code" && context.ParentScope()?.status.type === "switch") {
						context.scopes.pop();
						const newCase = {
							case: { owner: "switch-case", status: { type: "code" }, variables: {}, expression: [ [] ], identifiers: {}, description: "" },
							body: { owner: "switch-case", status: { type: "code" }, variables: {}, expression: [ [] ], identifiers: {}, description: "" },
						};
						context.CurrentScopePosition().slice(-1)[0].cases.push(newCase);
						context.scopes.push(newCase.case);
						return buildSpan(index, "color:aqua", match[0]);
					} else {
						return buildSpan(index, "color:red", match[0]);
					}
					break;
				case "Macro_MainBrowser_PrimaryClick":
					context.CurrentScopePosition().acceptedNamedParameters = [ "catchTimeout", "catchException" ];
					context.CurrentScopePosition().push({ index, line, column, file, type: "macro", value: match[0] });
					return buildSpan(index, "color:coral", match[0]);
					break;
				case "Macro_MainBrowser_GetAttribute":
					context.CurrentScopePosition().acceptedNamedParameters = [ "catchTimeout" ];
					context.CurrentScopePosition().push({ index, line, column, file, type: "macro", value: match[0] });
					return buildSpan(index, "color:coral", match[0]);
					break;
				case "Macro_MainBrowser_GetInnerHTML":
					context.CurrentScopePosition().acceptedNamedParameters = [ "catchTimeout" ];
					context.CurrentScopePosition().push({ index, line, column, file, type: "macro", value: match[0] });
					return buildSpan(index, "color:coral", match[0]);
					break;
				case "Macro_ShowWindow":
					context.CurrentScopePosition().acceptedNamedParameters = [ "catchTimeout", "disableInteractionDetection" ];
					context.CurrentScopePosition().push({ index, line, column, file, type: "macro", value: match[0] });
					return buildSpan(index, "color:coral", match[0]);
					break;
				case "Macro_Sleep":
				case "Macro_MainBrowser_Create":
				case "Macro_MainBrowser_Dispose":
				case "Macro_MainBrowser_ActivateCertificate":
				case "Macro_MainBrowser_Navigate":
				case "Macro_MainBrowser_SaveAs":
				case "Macro_MainBrowser_Write":
				case "Macro_JavaAccessBridge_SetStatus":
				case "Macro_JavaAccessBridge_GetStatus":
				case "Macro_JavaAccessBridge_EnumerateJVMs":
				case "Macro_JavaAccessBridge_EnumerateWindows":
				case "Macro_SendVirtualKeyCodes":
				case "Macro_TypeText":
				case "Macro_EnumerateProcesses":
				case "Macro_Count":
				case "Macro_Substring":
				case "Macro_Available":
				case "Macro_Read":
				case "Macro_Write":
				case "Macro_ReadMultiple":
				case "Macro_WriteMultiple":
				case "Macro_SuccessfulIterations":
				case "Macro_FailedIterations":
					context.CurrentScopePosition().push({ index, line, column, file, type: "macro", value: match[0] });
					return buildSpan(index, "color:coral", match[0]);
					break;
				case "Macro_RawBlock":
					context.CurrentScopePosition().acceptedNamedParameters = [ "timeout", "catchTimeout", "disableInteractionDetection" ];
					context.CurrentScopePosition().push({ index, line, column, file, type: "macro", value: match[0] });
					return buildSpan(index, "color:coral", match[0]);
					break;
				case "Macro_AddMemory":
					context.CurrentScopePosition().push({ index, line, column, file, type: "macro", value: match[0] });
					return buildSpan(index, "color:beige", match[0]);
					break;
				case "Macro_AddRequiredInput_ExcelIterator":
					context.CurrentScopePosition().acceptedNamedParameters = [ "title", "hint", "autoIterate", "headerRowIndex", "schema", "selectColumn", "columnTitle", "columnHint", "outputVariable", "outputFileName" ];
					context.CurrentScopePosition().push({ index, line, column, file, type: "macro", value: match[0] });
					return buildSpan(index, "color:beige", match[0]);
					break;
				case "Macro_AddRequiredInput_Date":
					context.CurrentScopePosition().acceptedNamedParameters = [ "title", "subtitle", "range" ];
					context.CurrentScopePosition().push({ index, line, column, file, type: "macro", value: match[0] });
					return buildSpan(index, "color:beige", match[0]);
					break;
				case "Macro_AddRequiredInput_DownloadFolder":
				case "Macro_AddInput_SendEmailOption":
					context.CurrentScopePosition().acceptedNamedParameters = [ "title", "hint" ];
					context.CurrentScopePosition().push({ index, line, column, file, type: "macro", value: match[0] });
					return buildSpan(index, "color:beige", match[0]);
					break;
				case "Macro_AddRequiredInput_ExcelIteratorWithFilenameColumn":
					context.CurrentScopePosition().acceptedNamedParameters = [ "title", "hint", "title2", "hint2", "autoIterate", "schema" ];
					context.CurrentScopePosition().push({ index, line, column, file, type: "macro", value: match[0] });
					return buildSpan(index, "color:beige", match[0]);
					break;
				case "Macro_AddRequiredInput_MultipleCertificates":
					context.CurrentScopePosition().acceptedNamedParameters = [ "autoIterate" ];
					context.CurrentScopePosition().push({ index, line, column, file, type: "macro", value: match[0] });
					return buildSpan(index, "color:beige", match[0]);
					break;
				case "Macro_AddInvisibleInput_LogStream":
				case "Macro_AddInvisibleInput_StreamIterator":
				case "Macro_AddInvisibleInput_UserLogStream":
				case "Macro_AddRequiredInput_SingleCertificate":
				case "Macro_AddRequiredInput_Selector":
				case "Macro_AddRequiredInput_Legacy_Excel":
					context.CurrentScopePosition().push({ index, line, column, file, type: "macro", value: match[0] });
					return buildSpan(index, "color:beige", match[0]);
					break;
				case "Macro_RawBlockJson_Input":
				case "Macro_RawBlockJson_Step":
				case "Macro_RawBlockJson_Variable":
					context.CurrentScopePosition().push({ index, line, column, file, type: "macro", value: match[0] });
					return buildSpan(index, "color:beige", match[0]);
					break;
				case "Macro_Breakpoint":
				case "Macro_Description":
					context.CurrentScopePosition().push({ index, line, column, file, type: "macro", value: match[0] });
					return buildSpan(index, "color:beige", match[0]);
					break;
				/*
				case "_System_sleep":
					context.CurrentScopePosition().push({ index, line, column, file, type: "function", value: match[0], compile: (context, expression, args, returnValue) => {
						const toAppend = { steps: [] };
						if (args.length !== 1) {
							context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires one parameter" ]);
							return toAppend;
						}
						toAppend.steps.push({ stepType: "VariableControl", action: "Sleep", variables: [ args[0].value ], description: context.FormatSourceRefAndDescription(expression[0]), timeout: !Number.isNaN(+args[0].value) && +args[0].value >= 9500 ? +args[0].value + 500 : undefined });
						return toAppend;
					} });
					return buildSpan(index, "color:violet", match[0]);
					break;
				case "_System_showWindow":
					context.CurrentScopePosition().push({ index, line, column, file, type: "function", value: match[0], compile: (context, expression, args, returnValue) => {
						const toAppend = { steps: [] };
						if (args.length !== 1 && args.length !== 2) {
							context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires one or two parameters" ]);
							return toAppend;
						}
						toAppend.steps.push(Object.assign({ stepType: "VariableControl", action: "ShowWindow", variables: [ ...(args.length === 1 ? [ "", args[0].value ] : [ args[0].value, args[1].value ]), "", "" ], description: context.FormatSourceRefAndDescription(expression[0]), timeout: args.catchTimeout && !Number.isNaN(+args.catchTimeout[0].value) ? +args.catchTimeout[0].value : 10000, catchTimeout: args.catchTimeout && !Number.isNaN(+args.catchTimeout[0].value), catchTimeout: !!args.catchTimeout }, args.disableInteractionDetection && args.disableInteractionDetection[0].value === 1 ? { disablePause: true } : {}));
						return toAppend;
					} });
					return buildSpan(index, "color:violet", match[0]);
					break;
				case "_System_showMessage":
					context.CurrentScopePosition().push({ index, line, column, file, type: "function", value: match[0], compile: (context, expression, args, returnValue) => {
						const toAppend = { steps: [] };
						if (args.length !== 1) {
							context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires one parameter" ]);
							return toAppend;
						}
						toAppend.steps.push(Object.assign({ stepType: "VariableControl", action: "ShowWindow", variables: [ "", args[0].value, "", "" ], description: context.FormatSourceRefAndDescription(expression[0]), timeout: args.catchTimeout && !Number.isNaN(+args.catchTimeout[0].value) ? +args.catchTimeout[0].value : 10000, catchTimeout: args.catchTimeout && !Number.isNaN(+args.catchTimeout[0].value), catchTimeout: !!args.catchTimeout }, args.disableInteractionDetection && args.disableInteractionDetection[0].value === 1 ? { disablePause: true } : {}));
						return toAppend;
					} });
					return buildSpan(index, "color:violet", match[0]);
					break;
				case "_MainBrowser_create":
					context.CurrentScopePosition().push({ index, line, column, file, type: "function", value: match[0], compile: (context, expression, args, returnValue) => {
						const toAppend = { steps: [] };
						if (args.length !== 0) {
							context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires no parameters" ]);
							return toAppend;
						}
						const variable = context.RequestTempVariable(expression[0]);
						Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
						toAppend.steps.push({ stepType: "BrowserAction", action: "CreateSeleniumBrowser", variables: [ returnValue.value ], description: context.FormatSourceRefAndDescription(expression[0]) });
						return toAppend;
					} });
					return buildSpan(index, "color:violet", match[0]);
					break;
				case "_MainBrowser_dispose":
					context.CurrentScopePosition().push({ index, line, column, file, type: "function", value: match[0], compile: (context, expression, args, returnValue) => {
						const toAppend = { steps: [] };
						if (args.length !== 0) {
							context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires no parameters" ]);
							return toAppend;
						}
						const variable = context.RequestTempVariable(expression[0]);
						Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
						toAppend.steps.push({ stepType: "BrowserAction", action: "DisposeSeleniumBrowser", variables: [ returnValue.value ], description: context.FormatSourceRefAndDescription(expression[0]) });
						return toAppend;
					} });
					return buildSpan(index, "color:violet", match[0]);
					break;
				*/
				default:
					if (context.CurrentScope().status.type === "import-as") {
						if (context.definedIdentifiers[match[0]]) {
							context.errors.push([ context.FormatSourceRefAndDescription({ index, line, column, file }), "keyword already defined", match[0] ]);
							return buildSpan(index, "color:red", match[0]);
						}
						const name = context.CurrentScopePosition().shift().value;
						const imported = context.ImportExternal(name);
						if (imported) {
							context.definedIdentifiers[match[0]] = { from: name, as: match[0], value: imported };
						}
						return buildSpan(index, imported ? "color:violet" : "color:red", match[0]);
					} else if (context.CurrentScope().status.type === "functionDef-keyword") {
						if (context.definedIdentifiers[match[0]]) {
							context.errors.push([ context.FormatSourceRefAndDescription({ index, line, column, file }), "keyword already defined", match[0] ]);
							return buildSpan(index, "color:red", match[0]);
						}
						context.definedIdentifiers[match[0]] = { as: match[0], value: { index, line, column, file, type: "function", value: match[0], compile: (context, expression, args, returnValue) => {
							const toAppend = { steps: [] };
							const variable = context.RequestTempVariable(expression[0]);
							Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
							toAppend.steps.push({ stepType: "VariableControl", action: "JumpAndLink", variables: [ returnValue.value, "fn_" + match[0], ...args.map(e => e.value) ], description: context.FormatSourceRefAndDescription(expression[0]) });
							return toAppend;
						}, header: "???" } };
						context.CurrentScope().status = { type: "functionDef-identifier" };
						context.CurrentScopePosition()[0].value = match[0];
						return buildSpan(index, "color:violet", match[0]);
					} else {
						const defined = context.definedIdentifiers[match[0]];
						if (defined) {
							context.CurrentScopePosition().push(Object.assign({}, defined.value, { index, line, column, file, value: match[0] }));
							if (Array.isArray(defined.value.acceptedNamedParameters)) {
								context.CurrentScopePosition().acceptedNamedParameters = [ ...defined.value.acceptedNamedParameters ];
							}
							return buildSpan(index, "color:violet", match[0], JSON.stringify({ imported: defined.from, header: defined.value.header }));
						} else {
							context.suggestions.splice(context.suggestions.length, 0, ...filterAndSort([
								...Object.keys(context.definedIdentifiers),
								"Macro_ShowWindow", "Macro_Sleep", "Macro_SuccessfulIterations", "Macro_FailedIterations",
								//
								"Macro_MainBrowser_ActivateCertificate", "Macro_MainBrowser_Create", "Macro_MainBrowser_Dispose", "Macro_MainBrowser_GetAttribute", "Macro_MainBrowser_GetInnerHTML", "Macro_MainBrowser_Navigate",
								"Macro_MainBrowser_PrimaryClick", "Macro_MainBrowser_SaveAs", "Macro_MainBrowser_Write", "Macro_Available", "Macro_Read", "Macro_Write", "Macro_ReadMultiple", "Macro_WriteMultiple",
								//
								"Macro_JavaAccessBridge_SetStatus", "Macro_JavaAccessBridge_GetStatus", "Macro_JavaAccessBridge_EnumerateJVMs", "Macro_JavaAccessBridge_EnumerateWindows",
								//
								"Macro_SendVirtualKeyCodes", "Macro_TypeText", "Macro_EnumerateProcesses", "Macro_Count", "Macro_Substring",
								//
								"Macro_RawBlock", "Macro_Breakpoint", "Macro_Description",
								//
								"Macro_AddMemory",
								//
								"Macro_AddInvisibleInput_LogStream", "Macro_AddInvisibleInput_StreamIterator", "Macro_AddInvisibleInput_UserLogStream",
								//
								"Macro_AddRequiredInput_DownloadFolder", "Macro_AddRequiredInput_ExcelIterator", "Macro_AddRequiredInput_MultipleCertificates",
								"Macro_AddRequiredInput_SingleCertificate", "Macro_AddRequiredInput_Selector", "Macro_AddRequiredInput_Date",
								//
								"Macro_AddInput_SendEmailOption",
								//
								"Macro_RawBlockJson_Input", "Macro_RawBlockJson_Step", "Macro_RawBlockJson_Variable",
								//
								"Macro_AddRequiredInput_Legacy_Excel", "Macro_AddRequiredInput_ExcelIteratorWithFilenameColumn",
							], match[0]).map(option => ({ option, remove: match[0].length })));
							context.errors.push([ context.FormatSourceRefAndDescription({ index, line, column, file }), "Undefined keyword", match[0] ]);
							return buildSpan(index, "color:red", match[0]);
						}
					}
			}
		} },
		{ key: "variable", regex: String.raw`\$[a-zA-Z_][a-zA-Z_0-9]*(?=\b)`, function: ({ match, index, line, column, file, input, groups, context, parentContext }) => {
			let found = null;
			switch (context.CurrentScope().status.type) {
				case "varDef-keyword":
					context.CurrentScope().status = { type: "varDef-name", name: match[0] };
					found = context.FindVariable(match[0], true);
					break;
				case "constDef-keyword":
					context.CurrentScope().status = { type: "constDef-name", name: match[0] };
					found = context.FindVariable(match[0], true).writable = false;
					break;
				case "varDef-assign":
					found = Object.assign(context.FindVariable(match[0], false) ?? {}, { read: true });
					break;
				case "constDef-assign":
					found = Object.assign(context.FindVariable(match[0], false) ?? {}, { read: true });
					break;
				default:
					found = Object.assign(context.FindVariable(match[0], false) ?? {}, { read: true });
			}
			context.CurrentScopePosition().push({ index, line, column, file, type: found.writable ? "variable" : "constant", value: match[0] });
			const variable = context.FindVariable(match[0], false);
			if (variable) {
				return buildSpan(index, "color:orange", match[0], JSON.stringify({ initialValue: variable.initialValue, currentValue: variable.currentValue }));
			} else {
				context.errors.push([ context.FormatSourceRefAndDescription({ index, line, column, file }), "Undeclared variable or constant" ]);
				context.suggestions.splice(context.suggestions.length, 0, ...filterAndSort(Object.keys(context.EnumerateVariables()), match[0]).map(option => ({ option, remove: match[0].length })));
				return buildSpan(index, "color:red", match[0]);
			}
		} },
		{ key: "operator", regex: String.raw`([=!<>]=|;|&&|\|\||<>|[()\[\]{},+*/%&|~^=<>?:-])`, function: ({ match, index, line, column, file, input, groups, context, parentContext }) => {
			switch (match[0]) {
				case ";":
					if (context.scopes.length === 1 && context.CurrentScope().status.type === "import-keyword") {
						const value = context.CurrentScopePosition()[0].value;
						context.suggestions.splice(context.suggestions.length, 0, ...filterAndSort(context.EnumerateExternals(), value).map(option => ({ option, remove: value.length })));
					} else if (context.scopes.length === 1 && context.CurrentScope().status.type !== "import-as") {
						const { toAppend: newToAppend } = context.BuildFromExpression(context.CurrentScopePosition());
						context.flow.steps.push(...newToAppend.steps);
					}
					context.CurrentScope().status = { type: "code" };
					context.CurrentScope().expression.push([]);
					break;
				case "=": {
					const type = context.CurrentScopePosition()[0].type;
					context.CurrentScopePosition().push({ index, line, column, file, type: [ "varDef-name", "constDef-name" ].includes(context.CurrentScope().status.type) ? "initialize" : "assign", value: "=", to: context.CurrentScopePosition().splice(0) });
					if (context.CurrentScope().status.type === "varDef-name") {
						context.CurrentScope().status = { type: "varDef-assign" };
					}
					if (context.CurrentScope().status.type === "constDef-name") {
						context.CurrentScope().status = { type: "constDef-assign" };
					}
				} break;
				case "?":
				case ":":
					context.CurrentScopePosition().push({ index, line, column, file, type: "operator", value: match[0], level: 1 });
					break;
				case "||":
					context.CurrentScopePosition().push({ index, line, column, file, type: "operator", value: match[0], level: 2 });
					break;
				case "&&":
					context.CurrentScopePosition().push({ index, line, column, file, type: "operator", value: match[0], level: 3 });
					break;
				case "==":
				case "!=":
				case ">":
				case "<":
				case ">=":
				case "<=":
					context.CurrentScopePosition().push({ index, line, column, file, type: "operator", value: match[0], level: 4 });
					break;
				case "<>":
					context.CurrentScopePosition().push({ index, line, column, file, type: "operator", value: match[0], level: 5 });
					break;
				case "+":
				case "-":
					context.CurrentScopePosition().push({ index, line, column, file, type: "operator", value: match[0], level: 6 });
					break;
				case "*":
				case "/":
				case "%":
					context.CurrentScopePosition().push({ index, line, column, file, type: "operator", value: match[0], level: 7 });
					break;
				case "(":
					context.CurrentScopePosition().push({ index, line, column, file, type: "grouper", value: "()", scope: { owner: "brackets", status: { type: "code" }, variables: {}, expression: [ [] ], identifiers: {}, description: "" } });
					context.scopes.push(context.CurrentScopePosition().slice(-1)[0].scope);
					break;
				case ")":
					if (context.CurrentScope().owner !== "brackets") {
						context.errors.push([ context.FormatSourceRefAndDescription({ index, line, column, file }), "( expected" ]);
					} else {
						context.scopes.pop();
					}
					if (context.CurrentScope().status.type === "functionDef-identifier") {
						context.CurrentScope().status.type = "functionDef-body";
						context.scopes.push(context.CurrentScopePosition().slice(-2, -1)[0].body);
					}
					break;
				case "[":
					context.CurrentScopePosition().push({ index, line, column, file, type: "accessor", value: "[]", scope: { owner: "accessor", status: { type: "code" }, variables: {}, expression: [ [] ], identifiers: {}, description: "" } });
					context.scopes.push(context.CurrentScopePosition().slice(-1)[0].scope);
					break;
				case "]":
					if (context.CurrentScope().owner !== "accessor") {
						context.errors.push([ context.FormatSourceRefAndDescription({ index, line, column, file }), "[ expected" ]);
					} else {
						context.scopes.pop();
					}
					break;
			}
			return buildSpan(index, "color:fuchsia", match[0]);
		} },
		{ key: "syntaxError", regex: String.raw`.+`, style: "color:red" },
	]);
	let selectedSuggestion = undefined;
	const processCode = () => {
		const src = sourceCodeEditor.textContent;
		const globalExternalContext = {
			externals: {
				"Desktop.EnumerateProcesses": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 0) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires no parameters" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "DesktopAction", action: "EnumerateProcesses", variables: [ returnValue.value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "(): list[process]" },
				"Desktop.SendVirtualKeyCodesSplitCommas": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 1) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires one parameter: ($stringListByCommas)" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "DesktopAction", action: "TypeVirtualKeyCodes", variables: [ returnValue.value, args[0].value, "," ], description: context.FormatSourceRefAndDescription(expression[0]), disablePause: true });
					return toAppend;
				}, header: "($stringListByCommas): int" },
				"Desktop.TypeText": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 1) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires one parameter: ($text)" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "DesktopAction", action: "TypeText", variables: [ returnValue.value, args[0].value ], description: context.FormatSourceRefAndDescription(expression[0]), disablePause: true });
					return toAppend;
				}, header: "($text): int" },
				"FileSystem.EnumerateDirectories": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 1) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires one parameter: ($path)" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "FileAction", action: "EnumerateDirectories", variables: [ returnValue.value, args[0].value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "($path): list[path]" },
				"FileSystem.EnumerateFiles": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 1) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires one parameter: ($path)" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "FileAction", action: "EnumerateFiles", variables: [ returnValue.value, args[0].value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "($path): list[path]" },
				"FileSystem.GetTimes": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 1) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires one parameter: ($path)" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "FileAction", action: "GetTimes", variables: [ returnValue.value, args[0].value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "($path): { atime, ctime, mtime }" },
				"FileSystem.AppendAllAsString": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 2) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires two parameters: ($path; $text)" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "FileAction", action: "AppendAllAsString", variables: [ returnValue.value, args[0].value, args[1].value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "($path; $text): int" },
				"FileSystem.ReadAllAsString": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 1) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires one parameter: ($path; [default: ''])" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "FileAction", action: "ReadAllAsString", variables: [ returnValue.value, args[0].value, args.default ? args.default[0].value : "" ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "($path; [default: '']): string", acceptedNamedParameters: [ "default" ] },
				"FileSystem.WriteAllAsString": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 2) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires two parameters: ($path; $text)" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "FileAction", action: "WriteAllAsString", variables: [ returnValue.value, args[0].value, args[1].value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "($path; $text): int" },
				"Iterator.Available": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 1 || args[0].type !== "variable") {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires one parameter: ($iterator)" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "IteratorOperation", action: "Available", variables: [ returnValue.value, args[0].value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "($iterator): int" },
				"Iterator.CreateXPathIterator": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 1) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires one parameter: ($xpath)" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "IteratorOperation", action: "CreateXPathIterator", variables: [ returnValue.value, args[0].value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "($xpath): iterator" },
				"Iterator.Read": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 1 || args[0].type !== "variable") {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires one parameter: ($iterator)" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "IteratorOperation", action: "Read", variables: [ returnValue.value, args[0].value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "($iterator): any" },
				"Iterator.ReadMultiple": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (!((args.length === 1 || args.length === 2) && args[0].type === "variable")) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires one parameter: ($iterator; [$count])" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "IteratorOperation", action: "ReadMultiple", variables: [ returnValue.value, args[0].value, args[1]?.value ?? "" ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "($iterator; [$count]): list[any]" },
				"Iterator.ReadOrFail": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (!((args.length === 2 || args.length === 3) && args[0].type === "variable" && args[1].type === "variable")) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires two or three parameters: ($iterator; $log; $message = 'No hay datos de entrada')" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					const source = `
import 'Iterator.Available' as Available;
import 'Iterator.Read' as Read;
import 'Log.Error' as Error;
import 'System.EndWithFailure' as EndWithFailure;
var %iterator;
var %log;
var %result;
if ((Available(%iterator)) == 0) then
  Error(%log; %message);
  EndWithFailure();
end;
%result = Read(%iterator);`
						.replaceAll("%iterator", () => args[0].value)
						.replaceAll("%log", () => args[1].value)
						.replaceAll("%message", () => context.PutOnSource(args[2]) ?? "'No hay datos de entrada'")
						.replaceAll("%result", () => returnValue.value);
					const compiled = replaceSourceToHTML(source, { startIndex: 0, startLine: 1, startColumn: 0, srcFile: "-" }, globalExternalContext)[1];
					const { toAppend: newToAppend } = context.BuildFromExpressionList(compiled.scopes[0].expression);
					toAppend.steps.push(...newToAppend.steps);
					return toAppend;
				}, header: "($iterator; $log; $message = 'No hay datos de entrada'): any" },
				"Iterator.Write": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 2 || args[0].type !== "variable") {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires two parameters: ($iterator, $value)" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "IteratorOperation", action: "Write", variables: [ returnValue.value, args[0].value, args[1].value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "($iterator; $value): int" },
				"Iterator.WriteMultiple": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 2 || args[0].type !== "variable") {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires two parameters: ($iterator, $valuesList)" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "IteratorOperation", action: "WriteMultiple", variables: [ returnValue.value, args[0].value, args[1].value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "($iterator; $valuesList): int" },
				"JSON.Count": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 1) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires one parameter: ($list)" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "JSONOperation", action: "Count", variables: [ returnValue.value, args[0].value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "($list): int" },
				"JSON.GetType": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 1) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires one parameter: ($any)" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "JSONOperation", action: "GetType", variables: [ returnValue.value, args[0].value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "($any): string" },
				"JSON.Join": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 2) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires two parameters: ($list; $separator)" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "JSONOperation", action: "Join", variables: [ returnValue.value, args[0].value, args[1].value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "($object): string" },
				"JSON.ObjectToEntryList": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 1) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires one parameter: ($object)" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "JSONOperation", action: "ObjectToEntryList", variables: [ returnValue.value, args[0].value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "($object): list[string; any]" },
				"JSON.Parse": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 1) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires one parameter: ($string)" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "JSONOperation", action: "Parse", variables: [ returnValue.value, args[0].value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "($string): object" },
				"JSON.ToString": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 1) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires one parameter: ($object)" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "JSONOperation", action: "ToString", variables: [ returnValue.value, args[0].value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "($object): string" },
				"JSON.TrimValues": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (!(args.length === 2 && args[0].type === "variable" && args[1].type === "variable")) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires 2 parameters: ($object; $result)" ]);
						return toAppend;
					}
					Object.assign(returnValue, { type: "variable", value: args[0].value, toFree: false });
					const source = `
import 'JSON.ObjectToEntryList' as ObjectToEntryList;
import 'String.Trim' as Trim;
var %object;
var %result;
foreach %result in (ObjectToEntryList(%object)) do
  %object[%result[0]] = Trim(%result[1]);
end;`
						.replaceAll("%object", () => args[0].value)
						.replaceAll("%result", () => args[1].value);
					const compiled = replaceSourceToHTML(source, { startIndex: 0, startLine: 1, startColumn: 0, srcFile: "-" }, globalExternalContext)[1];
					const { toAppend: newToAppend } = context.BuildFromExpressionList(compiled.scopes[0].expression);
					toAppend.steps.push(...newToAppend.steps);
					return toAppend;
				}, header: "(out $object; $result): object" },
				"JSON.TrimValuesOnlyString": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (!(args.length === 2 && args[0].type === "variable" && args[1].type === "variable")) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires 2 parameters: ($object; $result)" ]);
						return toAppend;
					}
					Object.assign(returnValue, { type: "variable", value: args[0].value, toFree: false });
					const source = `
import 'JSON.GetType' as GetType;
import 'JSON.ObjectToEntryList' as ObjectToEntryList;
import 'String.Trim' as Trim;
var %object;
var %result;
foreach %result in (ObjectToEntryList(%object)) do
  if ((GetType(%result[1])) == 'String') then
    %object[%result[0]] = Trim(%result[1]);
  end;
end;`
						.replaceAll("%object", () => args[0].value)
						.replaceAll("%result", () => args[1].value);
					const compiled = replaceSourceToHTML(source, { startIndex: 0, startLine: 1, startColumn: 0, srcFile: "-" }, globalExternalContext)[1];
					const { toAppend: newToAppend } = context.BuildFromExpressionList(compiled.scopes[0].expression);
					toAppend.steps.push(...newToAppend.steps);
					return toAppend;
				}, header: "(out $object; $result): object" },
				"KeyboardShortcut.ClearCurrentTextBox": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 0) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires no parameters: ([delay: *ms*])" ]);
						return toAppend;
					}
					toAppend.steps.push({ stepType: "DesktopAction", action: "TypeVirtualKeyCodes", variables: [ "", "HOME", "," ], description: context.FormatSourceRefAndDescription(expression[0]), disablePause: true });
					if (args.delay && !Number.isNaN(+args.delay[0].value)) {
						toAppend.steps.push({ stepType: "VariableControl", action: "Sleep", variables: [ args.delay[0].value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					}
					toAppend.steps.push({ stepType: "DesktopAction", action: "TypeVirtualKeyCodes", variables: [ "", "SHIFT,END", "," ], description: context.FormatSourceRefAndDescription(expression[0]), disablePause: true });
					if (args.delay && !Number.isNaN(+args.delay[0].value)) {
						toAppend.steps.push({ stepType: "VariableControl", action: "Sleep", variables: [ args.delay[0].value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					}
					toAppend.steps.push({ stepType: "DesktopAction", action: "TypeVirtualKeyCodes", variables: [ "", "DELETE", "," ], description: context.FormatSourceRefAndDescription(expression[0]), disablePause: true });
					return toAppend;
				}, header: "([delay: *ms*])" },
				"Log.Error": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 2 || args[0].type !== "variable") {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires two parameters: ($log; $message)" ]);
						return toAppend;
					}
					toAppend.steps.push({ stepType: "LogStreamAction", action: "Error", variables: [ args[0].value, args[1].value, "[]" ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "($log; $message)" },
				"Log.Log": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 2 || args[0].type !== "variable") {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires two parameters: ($log; $message)" ]);
						return toAppend;
					}
					toAppend.steps.push({ stepType: "LogStreamAction", action: "Log", variables: [ args[0].value, args[1].value, "[]" ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "($log; $message)" },
				"MainBrowser.ActivateCertificate": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 2 || args[0].type !== "variable" || args[1].type !== "string") {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires two parameters: ($certificate; $host)" ]);
						return toAppend;
					}
					context.flow.digitalCertificateRequired = true;
					context.flow.webDomain = args[1].value;
					return toAppend;
				}, header: "($certificate; $host)" },
				"MainBrowser.BringToFront": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 0) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires no parameters" ]);
						return toAppend;
					}
					toAppend.steps.push({ stepType: "BrowserAction", action: "BringBrowserToFront", variables: [], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "()" },
				"MainBrowser.ChangeToLastWindow": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 0) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires no parameters" ]);
						return toAppend;
					}
					toAppend.steps.push({ stepType: "BrowserAction", action: "ChangeWindow", variables: [], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "()" },
				"MainBrowser.CloseWindow": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 0 && args.length !== 1) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires one or no parameters: ($handle = '')" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "BrowserAction", action: "CloseWindow", variables: [ args[0] ? args[0].value : "", returnValue.value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "($handle = ''): bool" },
				"MainBrowser.Create": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 0) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires no parameters" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "BrowserAction", action: "CreateSeleniumBrowser", variables: [ returnValue.value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "()" },
				"MainBrowser.CreateOrFail": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (!((args.length === 2 || args.length === 3 && [ "string", "variable" ].includes(args[2].type)) && args[0].type === "variable" && args[1].type === "variable")) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires 2 or 3 parameters: ($log; $result; $message = 'No se ha podido lanzar el navegador')" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					const source = `
import 'Log.Error' as Error;
import 'System.EndWithFailure' as EndWithFailure;
var %log;
var %result;
Macro_RawBlock 'BrowserAction' 'CreateSeleniumBrowser' %result;
if (%result != '') then
  Error(%log; %message);
  EndWithFailure();
end;`
						.replaceAll("%log", () => args[0].value)
						.replaceAll("%result", () => args[1].value)
						.replaceAll("%message", () => context.PutOnSource(args[2]) ?? "'No se ha podido lanzar el navegador'");
					const compiled = replaceSourceToHTML(source, { startIndex: 0, startLine: 1, startColumn: 0, srcFile: "-" }, globalExternalContext)[1];
					const { toAppend: newToAppend } = context.BuildFromExpressionList(compiled.scopes[0].expression);
					toAppend.steps.push(...newToAppend.steps);
					return toAppend;
				}, header: "($log; $result; $message = 'No se ha podido lanzar el navegador')" },
				"MainBrowser.Dispose": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 0) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires no parameters" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "BrowserAction", action: "DisposeSeleniumBrowser", variables: [ returnValue.value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "()" },
				"MainBrowser.GetInnerHTML": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 1) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires one parameter: ($xpath; [catchTimeout: *ms*]; [notFound: ''])" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "VariableOperation", action: "Set", variables: [ returnValue.value, args.notFound ? args.notFound[0].value : "" ] });
					toAppend.steps.push(Object.assign(
						{ stepType: "BrowserAction", action: "GetHTMLCodeByLocator", variables: [ args[0].value, returnValue.value ], description: context.FormatSourceRefAndDescription(expression[0]) },
						args.catchTimeout?.[0]?.type === "number" && { timeout: +args.catchTimeout[0].value, catchTimeout: true },
					));
					return toAppend;
				}, header: "($xpath; [catchTimeout: *ms*]; [notFound = '']): string", acceptedNamedParameters: [ "catchTimeout", "notFound" ] },
				"MainBrowser.GetWindowList": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 0) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires no parameters" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "BrowserAction", action: "GetWindowList", variables: [ returnValue.value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "(): list" },
				"MainBrowser.MovePointer": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 1 && args.length !== 2 && args.length !== 3) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires one, two or three parameters: ($xpath; [$deltaX; $deltaY]) | ($deltaX; $deltaY)" ]);
						return toAppend;
					}
					toAppend.steps.push(Object.assign(
						{ stepType: "BrowserAction", action: "MovePointer", variables: args.length === 2 ? [ "", +args[0].value, +args[1].value ] : [ args[0].value, +(args[1]?.value ?? "0"), +(args[2]?.value ?? "0") ], description: context.FormatSourceRefAndDescription(expression[0]) },
						args.catchTimeout && !Number.isNaN(+args.catchTimeout[0].value) ? { timeout: +args.catchTimeout[0].value, catchTimeout: true } : undefined,
					));
					return toAppend;
				}, header: "($xpath; [$deltaX; $deltaY]) | ($deltaX; $deltaY)", acceptedNamedParameters: [ "catchTimeout" ] },
				"MainBrowser.Navigate": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 1) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires one parameter: ($url; [catchTimeout: *ms*])" ]);
						return toAppend;
					}
					toAppend.steps.push(Object.assign({ stepType: "BrowserAction", action: "NavigateToUrl", variables: [ args[0].value ], description: context.FormatSourceRefAndDescription(expression[0]) }, args.catchTimeout && !Number.isNaN(+args.catchTimeout[0].value) ? { timeout: +args.catchTimeout[0].value, catchTimeout: true } : undefined));
					return toAppend;
				}, header: "($url; [catchTimeout: *ms*])", acceptedNamedParameters: [ "catchTimeout" ] },
				"MainBrowser.PrimaryClick": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 1) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires one parameter: ($xpath)" ]);
						return toAppend;
					}
					toAppend.steps.push(Object.assign(
						{ stepType: "BrowserAction", action: "PrimaryClick", variables: [ args[0].value ], description: context.FormatSourceRefAndDescription(expression[0]) },
						args.catchTimeout && !Number.isNaN(+args.catchTimeout[0].value) ? { timeout: +args.catchTimeout[0].value, catchTimeout: true } : undefined,
					));
					return toAppend;
				}, header: "($xpath; [catchTimeout: *ms*])", acceptedNamedParameters: [ "catchTimeout" ] },
				"MainBrowser.SaveAs": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 4) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires four parameters: ($outputFileName; $outputFolder; xpath; $outputFolderDefaultName)" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "BrowserAction", action: "SaveAs", variables: [ args[0].value, args[1].value, args[2].value, args[3].value, returnValue.value ], description: context.FormatSourceRefAndDescription(expression[0]), timeout: 20000, disablePause: true });
					return toAppend;
				}, header: "($outputFileName; $outputFolder; $xpath; $outputFolderDefaultName): string" },
				"MainBrowser.Scroll": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 1 && args.length !== 2 && args.length !== 3) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires one, two or three parameters: ($xpath; [$deltaX; $deltaY]) | ($deltaX; $deltaY)" ]);
						return toAppend;
					}
					toAppend.steps.push(Object.assign(
						{ stepType: "BrowserAction", action: "Scroll", variables: args.length === 2 ? [ "", +args[0].value, +args[1].value ] : [ args[0].value, +(args[1]?.value ?? "0"), +(args[2]?.value ?? "0") ], description: context.FormatSourceRefAndDescription(expression[0]) },
						args.catchTimeout && !Number.isNaN(+args.catchTimeout[0].value) ? { timeout: +args.catchTimeout[0].value, catchTimeout: true } : undefined,
					));
					return toAppend;
				}, header: "($xpath; [$deltaX; $deltaY]) | ($deltaX; $deltaY)", acceptedNamedParameters: [ "catchTimeout" ] },
				"MainBrowser.SwitchToFrame": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 1) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires one parameter: ($xpath)" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "BrowserAction", action: "SwitchToFrame", variables: [ args[0].value ], description: context.FormatSourceRefAndDescription(expression[0]), timeout: 20000, disablePause: true });
					return toAppend;
				}, header: "($xpath)" },
				"MainBrowser.SwitchToParentFrame": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 0) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires no parameters" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "BrowserAction", action: "SwitchToParentFrame", variables: [ args[0].value ], description: context.FormatSourceRefAndDescription(expression[0]), timeout: 20000, disablePause: true });
					return toAppend;
				}, header: "()" },
				"MainBrowser.SwitchToWindow": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 1) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires one parameter: ($handle)" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "BrowserAction", action: "SwitchToWindow", variables: [ args[0].value, returnValue.value ], description: context.FormatSourceRefAndDescription(expression[0]), timeout: 20000, disablePause: true });
					return toAppend;
				}, header: "($handle): string" },
				"MainBrowser.TypeInTextBox": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 2) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires two parameters: ($xpath; $text)" ]);
						return toAppend;
					}
					toAppend.steps.push({ stepType: "BrowserAction", action: "TypeInTextBox", variables: [ args[0].value, args[1].value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "($xpath; $text)" },
				"Random.RandomInt": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 0) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires no parameters" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "VariableOperation", action: "RandomInt", variables: [ returnValue.value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "(): int" },
				"Regex.MatchGroup": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 2 && args.length !== 3) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires three parameters: ($regex; $text; $group = 0)" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "VariableOperation", action: "MatchGroup", variables: [ returnValue.value, args[0].value, args[1].value, args[2] ? args[2].value : 0 ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "($regex; $text; $group = 0): string" },
				"Regex.Replace": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 3) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires three parameters: ($regex; $text; $replacement)" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "VariableOperation", action: "ReplaceRegex", variables: [ returnValue.value, args[1].value, args[0].value, args[2].value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "($regex; $text; $replacement): string" },
				"Regex.Is.Dni": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 0) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires no parameters" ]);
						return toAppend;
					}
					Object.assign(returnValue, { type: "string", value: "/^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/i", toFree: false });
					return toAppend;
				}, header: "(): regex" },
				"Regex.Is.Nie": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 0) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires no parameters" ]);
						return toAppend;
					}
					Object.assign(returnValue, { type: "string", value: "/^[XYZ][0-9]{7,8}[TRWAGMYFPDXBNJZSQVHLCKE]$/i", toFree: false });
					return toAppend;
				}, header: "(): regex" },
				"Regex.Is.Passport": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 0) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires no parameters" ]);
						return toAppend;
					}
					Object.assign(returnValue, { type: "string", value: "/^[A-Z]{3}[0-9]{6}[A-Z]?$/i", toFree: false });
					return toAppend;
				}, header: "(): regex" },
				"Regex.Is.Cif": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 0) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires no parameters" ]);
						return toAppend;
					}
					// https://regex101.com/r/itKt76/1
					Object.assign(returnValue, { type: "string", value: "/^(?>[PQRSW]{1}-?\\d{7}-?[A-Z]{1})$|^(?>[ABEH]{1}-?\\d{7}-?\\d{1})$|^(?>[CDFGJNU]{1}-?\\d{7}-?[A-Z0-9]{1})$|^(?>[ABCDEFGHJNPQRSUVW]{1}-?00\\d{5}-?[A-Z]{1})$/i", toFree: false });
					return toAppend;
				}, header: "(): regex" },
				"Regex.SwitchBasedOnRegex": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 1) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires one parameter: ($value; [ifDni: *value*]; [ifNie: *value*]; [ifPassport: *value*]; [ifCif: *value*]; [else: *value* = ''])" ]);
						return toAppend;
					}
					let preSource = "%else";
					if (args.ifDni) {
						preSource = "(MatchGroup(DniRegex(); %value)) ? %ifDni : (" + preSource + ")";
					}
					if (args.ifNie) {
						preSource = "(MatchGroup(NieRegex(); %value)) ? %ifNie : (" + preSource + ")";
					}
					if (args.ifPassport) {
						preSource = "(MatchGroup(PassportRegex(); %value)) ? %ifPassport : (" + preSource + ")";
					}
					if (args.ifCif) {
						preSource = "(MatchGroup(CifRegex(); %value)) ? %ifCif : (" + preSource + ")";
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					const source = `
import 'Regex.Is.Cif' as CifRegex;
import 'Regex.Is.Dni' as DniRegex;
import 'Regex.Is.Nie' as NieRegex;
import 'Regex.Is.Passport' as PassportRegex;
import 'Regex.MatchGroup' as MatchGroup;
${args[0].type === "variable" ? "var %value;" : ""}
var %result = ${preSource};`
						.replaceAll("%value", () => context.PutOnSource(args[0]))
						.replaceAll("%result", () => returnValue.value)
						.replaceAll("%ifDni", () => context.PutOnSource(args.ifDni?.[0]) ?? "''")
						.replaceAll("%ifNie", () => context.PutOnSource(args.ifNie?.[0]) ?? "''")
						.replaceAll("%ifPassport", () => context.PutOnSource(args.ifPassport?.[0]) ?? "''")
						.replaceAll("%ifCif", () => context.PutOnSource(args.ifCif?.[0]) ?? "''")
						.replaceAll("%else", () => context.PutOnSource(args.else?.[0]) ?? "''");
					const compiled = replaceSourceToHTML(source, { startIndex: 0, startLine: 1, startColumn: 0, srcFile: "-" }, globalExternalContext)[1];
					const { toAppend: newToAppend } = context.BuildFromExpressionList(compiled.scopes[0].expression);
					toAppend.steps.push(...newToAppend.steps);
					return toAppend;
				}, header: "($value; [ifDni: *value*]; [ifNie: *value*]; [ifPassport: *value*]; [ifCif: *value*]; [else: *value*]): string", acceptedNamedParameters: [ "ifDni", "ifNie", "ifPassport", "ifCif", "else" ] },
				"SeguridadSocial.GetDILError": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 0) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires no parameters" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					const source = `
import 'MainBrowser.GetInnerHTML' as GetInnerHTML;
import 'String.Trim' as Trim;
var %result;
%result = Trim(GetInnerHTML('//label[@id="DIL"]'));`
						.replaceAll("%result", () => returnValue.value);
					const compiled = replaceSourceToHTML(source, { startIndex: 0, startLine: 1, startColumn: 0, srcFile: "-" }, globalExternalContext)[1];
					const { toAppend: newToAppend } = context.BuildFromExpressionList(compiled.scopes[0].expression);
					toAppend.steps.push(...newToAppend.steps);
					return toAppend;
				}, header: "(): stringError|''(ok)" },
				"SeguridadSocial.JoinCCCOrFail": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if ((args.length !== 5 && args.length !== 6) || args[0].type !== "variable" || args[4].type !== "variable") {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires 5 or 6 parameters: ($object; $regimenField; $provinciaField; $cccField; $log; $error = 'La longitud de los campos del CCC no es válida')" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					const source = `
import 'Log.Error' as Error;
import 'System.EndWithFailure' as EndWithFailure;
import 'String.Length' as Length;
var %object;
${[ "variable", "constant" ].includes(args[1].type) ? "var %regimenField" : ""};
${[ "variable", "constant" ].includes(args[2].type) ? "var %provinciaField" : ""};
${[ "variable", "constant" ].includes(args[3].type) ? "var %cccField" : ""};
var %log;
var %result;
%result = Length(%object[%cccField]);
switch %result
  case 9 do // Régimen + CCCProvincia + CCC
    %result = %object[%regimenField] <> %object[%provinciaField] <> %object[%cccField];
  case 11 do // Régimen + CCC
    %result = %object[%regimenField] <> %object[%cccField];
  case 14 do // CCC (Régimen needs 0)
    %result = '0' <> %object[%cccField];
  case 15 do // CCC
    %result = 'Ok';
  case %result do
    %result = 'Error';
end;
if (%result != 'Ok') then
  if (Length(%result) == 15) then
    %object[%cccField] = %result;
  else
    if (Length(%result) == 14) then
      %object[%cccField] = '0' <> %result;
    else
      Error($userLogStream; '[' <> %object[%regimenField] <> '+' <> %object[%provinciaField] <> '+' <> %object[%cccField] <> ']: ' <> %error);
      EndWithFailure();
    end;
  end;
end;
%result = %object;`
						.replaceAll("%object", () => context.PutOnSource(args[0]))
						.replaceAll("%regimenField", () => context.PutOnSource(args[1]))
						.replaceAll("%provinciaField", () => context.PutOnSource(args[2]))
						.replaceAll("%cccField", () => context.PutOnSource(args[3]))
						.replaceAll("%log", () => context.PutOnSource(args[4]))
						.replaceAll("%error", () => context.PutOnSource(args?.[5]) ?? "'La longitud de los campos del CCC no es válida'")
						.replaceAll("%result", () => returnValue.value);
					const compiled = replaceSourceToHTML(source, { startIndex: 0, startLine: 1, startColumn: 0, srcFile: "-" }, globalExternalContext)[1];
					const { toAppend: newToAppend } = context.BuildFromExpressionList(compiled.scopes[0].expression);
					toAppend.steps.push(...newToAppend.steps);
					return toAppend;
				}, header: "(out $object; $regimenField; $provinciaField; $cccField; $log; $error = 'La longitud de los campos del CCC no es válida'): object" },
				"SeguridadSocial.TerminateIfDILError": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 2 || args[0].type !== "variable") {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires two parameters: ($log; $key)" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					const source = `
import 'Log.Error' as Error;
import 'MainBrowser.GetInnerHTML' as GetInnerHTML;
import 'String.Trim' as Trim;
import 'System.EndWithFailure' as EndWithFailure;
var %log;
var %key;
var %result;
%result = Trim(GetInnerHTML('//label[@id="DIL"]'));
if (%result != '') then
  Error(%log; "[%key]: %result");
  EndWithFailure();
end;`
						.replaceAll("%log", () => args[0].value)
						.replaceAll("%key", () => context.PutOnSource(args[1]))
						.replaceAll("%result", () => returnValue.value);
					const compiled = replaceSourceToHTML(source, { startIndex: 0, startLine: 1, startColumn: 0, srcFile: "-" }, globalExternalContext)[1];
					const { toAppend: newToAppend } = context.BuildFromExpressionList(compiled.scopes[0].expression);
					toAppend.steps.push(...newToAppend.steps);
					return toAppend;
				}, header: "($log; $key)" },
				"SeguridadSocial.GetDialogoError": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 0) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires no parameters" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					const source = `
import 'MainBrowser.GetInnerHTML' as GetInnerHTML;
import 'String.Trim' as Trim;
var %result;
%result = Trim(GetInnerHTML('//div[@id="dialogoMensajes"]/ul/li[@class="ERROR mensaje"]/p'; catchTimeout: 1000));`
						.replaceAll("%result", () => returnValue.value);
					const compiled = replaceSourceToHTML(source, { startIndex: 0, startLine: 1, startColumn: 0, srcFile: "-" }, globalExternalContext)[1];
					const { toAppend: newToAppend } = context.BuildFromExpressionList(compiled.scopes[0].expression);
					toAppend.steps.push(...newToAppend.steps);
					return toAppend;
				}, header: "(): stringError|''(ok)" },
				"SeguridadSocial.TerminateIfDialogoError": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 2 || args[0].type !== "variable") {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires two parameters: ($log; $key)" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					const source = `
import 'Log.Error' as Error;
import 'MainBrowser.GetInnerHTML' as GetInnerHTML;
import 'String.Trim' as Trim;
import 'System.EndWithFailure' as EndWithFailure;
var %log;
var %key;
var %result;
%result = Trim(GetInnerHTML('//div[@id="dialogoMensajes"]/ul/li[@class="ERROR mensaje"]/p'; catchTimeout: 1000));
if (%result != '') then
  Error(%log; "[%key]: %result");
  EndWithFailure();
end;`
						.replaceAll("%log", () => args[0].value)
						.replaceAll("%key", () => context.PutOnSource(args[1]))
						.replaceAll("%result", () => returnValue.value);
					const compiled = replaceSourceToHTML(source, { startIndex: 0, startLine: 1, startColumn: 0, srcFile: "-" }, globalExternalContext)[1];
					const { toAppend: newToAppend } = context.BuildFromExpressionList(compiled.scopes[0].expression);
					toAppend.steps.push(...newToAppend.steps);
					return toAppend;
				}, header: "($log; $key)" },
				"SeguridadSocial.GetNoCertificateAccess": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 0) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires no parameters" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					const source = `
import 'MainBrowser.GetInnerHTML' as GetInnerHTML;
import 'String.Trim' as Trim;
var %result;
%result = Trim(GetInnerHTML('//ul[li/h3[text()="Acceso no autorizado"]]/li/p'; catchTimeout: 1000));`
						.replaceAll("%result", () => returnValue.value);
					const compiled = replaceSourceToHTML(source, { startIndex: 0, startLine: 1, startColumn: 0, srcFile: "-" }, globalExternalContext)[1];
					const { toAppend: newToAppend } = context.BuildFromExpressionList(compiled.scopes[0].expression);
					toAppend.steps.push(...newToAppend.steps);
					return toAppend;
				}, header: "(): stringError|''(ok)" },
				"SeguridadSocial.TerminateIfNoCertificateAccess": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 2 || args[0].type !== "variable") {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires two parameters: ($log; $key)" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					const source = `
import 'Log.Error' as Error;
import 'MainBrowser.GetInnerHTML' as GetInnerHTML;
import 'String.Trim' as Trim;
import 'System.EndWithFailure' as EndWithFailure;
var %log;
var %key;
var %result;
%result = Trim(GetInnerHTML('//ul[li/h3[text()="Acceso no autorizado"]]/li/p'; catchTimeout: 1000));
if (%result != '') then
  Error(%log; "[%key]: %result");
  EndWithFailure();
end;`
						.replaceAll("%log", () => args[0].value)
						.replaceAll("%key", () => context.PutOnSource(args[1]))
						.replaceAll("%result", () => returnValue.value);
					const compiled = replaceSourceToHTML(source, { startIndex: 0, startLine: 1, startColumn: 0, srcFile: "-" }, globalExternalContext)[1];
					const { toAppend: newToAppend } = context.BuildFromExpressionList(compiled.scopes[0].expression);
					toAppend.steps.push(...newToAppend.steps);
					return toAppend;
				}, header: "($log; $key)" },
				"SlangoAPI.UpdateFormToFlowSubmissionAndSendEmail": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 1) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires one parameter: ($formToFlowSubmission; [docPath: *path*]; [idcPath: *path*])" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "VariableOperation", action: "Set", variables: [ returnValue.value, "{\"doc\":\"\",\"idc\":\"\"}" ], description: context.FormatSourceRefAndDescription(expression[0]) });
					if (args.docPath) {
						toAppend.steps.push({ stepType: "JSONOperation", action: "SetValue", variables: [ returnValue.value, returnValue.value, "doc", args.docPath[0].value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					}
					if (args.idcPath) {
						toAppend.steps.push({ stepType: "JSONOperation", action: "SetValue", variables: [ returnValue.value, returnValue.value, "idc", args.idcPath[0].value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					}
					toAppend.steps.push({ stepType: "SlangoAPI", action: "UpdateFormToFlowSubmissionAndSendEmail", variables: [ returnValue.value, args[0].value, returnValue.value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "($formToFlowSubmission; [docPath: *path*]; [idcPath: *path*]): 0(error)|1(ok)", acceptedNamedParameters: [ "docPath", "idcPath" ] },
				"String.Length": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 1) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires one parameter: ($string)" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "VariableOperation", action: "Length", variables: [ returnValue.value, args[0].value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "($string): int" },
				"String.Replace": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 3) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires three parameters: ($string; $search; $replacement)" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "VariableOperation", action: "Replace", variables: [ returnValue.value, args[0].value, args[1].value, args[2].value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "($string; $search; $replacement): string" },
				"String.Substring": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 3) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires three parameters: ($string; $start; $length)" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "VariableOperation", action: "Substring", variables: [ returnValue.value, args[0].value, args[1].value, args[2].value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "($string; $start; $length): string" },
				"String.Trim": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 1) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires one parameter: ($string)" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "VariableOperation", action: "Trim", variables: [ returnValue.value, args[0].value, "" ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "($string): string" },
				"StringList.ElementAt": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 3) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires three parameters: ($stringList; $separator; $position)" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "VariableOperation", action: "ElementAt", variables: [ returnValue.value, args[0].value, args[1].value, args[2].value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "($stringList; $separator; $position): string" },
				"StringSet.Difference": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 3) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires three parameters: ($firstSet; $secondSet; $separator)" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "VariableOperation", action: "Difference", variables: [ returnValue.value, args[0].value, args[1].value, args[2].value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "($firstSet; $secondSet; $separator): string" },
				"System.End": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 0) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires no parameters" ]);
						return toAppend;
					}
					toAppend.steps.push({ stepType: "VariableControl", action: "End", variables: [], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "()" },
				"System.EndWithFailure": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 0) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires no parameters" ]);
						return toAppend;
					}
					toAppend.steps.push({ stepType: "VariableControl", action: "EndWithFailure", variables: [], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "()" },
				"System.RawBlock": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length < 2 || args[0].type !== "string" || args[1].type !== "string") {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires at least two parameters: ($stepType; $action; $variable...; [timeout: *ms*]; [catchTimeout: *ms*]; [disableInteractionDetection: 0|1]; [key: *key*])" ]);
						return toAppend;
					}
					toAppend.steps.push(Object.assign(
						{ stepType: args[0].value, action: args[1].value, variables: args.slice(2).map(e => e.value), description: context.FormatSourceRefAndDescription(expression[0]) },
						args.key ? { key: args.key[0].value } : undefined,
						args.timeout && !Number.isNaN(+args.timeout[0].value) ? { timeout: +args.timeout[0].value } : undefined,
						args.catchTimeout && !Number.isNaN(+args.catchTimeout[0].value) ? { timeout: +args.catchTimeout[0].value, catchTimeout: true } : undefined,
						args.disableInteractionDetection && args.disableInteractionDetection[0] === 1 ? { disablePause: true } : undefined,
					));
					return toAppend;
				}, header: "($stepType; $action; $variable...; [timeout: *ms*]; [catchTimeout: *ms*]; [disableInteractionDetection: 0|1])", acceptedNamedParameters: [ "timeout", "catchTimeout", "disableInteractionDetection", "key" ] },
				"System.RawMark": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 1 || args[0].type !== "string") {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires one parameter: ($key)" ]);
						return toAppend;
					}
					toAppend.steps.push({ stepType: "VariableControl", action: "Sleep", variables: [ 0 ], key: args[0].value });
					return toAppend;
				}, header: "($stepType; $action; $variable...; [timeout: *ms*]; [catchTimeout: *ms*]; [disableInteractionDetection: 0|1])", acceptedNamedParameters: [ "timeout", "catchTimeout", "disableInteractionDetection", "key" ] },
				"System.FileExistsOrFail": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 1) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires one parameter: ($path)" ]);
						return toAppend;
					}
					toAppend.steps.push({ stepType: "VariableAction", action: "CheckFilePath", variables: [ args[0].value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "($path)" },
				"System.MaybeSendFileByEmail": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 3) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires three parameters: ($condition; $email; $path)" ]);
						return toAppend;
					}
					toAppend.steps.push({ stepType: "VariableAction", action: "SendFileByEmail", variables: [ args[0].value, args[1].value, args[2].value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "($path)" },
				"System.Sleep": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 1) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires one parameter: ($ms)" ]);
						return toAppend;
					}
					toAppend.steps.push(Object.assign({ stepType: "VariableControl", action: "Sleep", variables: [ Number.isNaN(+args[0].value) ? args[0].value : +args[0].value ], description: context.FormatSourceRefAndDescription(expression[0]) }, !Number.isNaN(args[0].value) && +args[0].value > 9500 ? { timeout: +args[0].value + 500 } : undefined));
					return toAppend;
				}, header: "($ms)" },
				"System.ShowWindow": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 1 && args.length !== 2) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires one or two parameters" ]);
						return toAppend;
					}
					toAppend.steps.push(Object.assign(
						{ stepType: "VariableControl", action: "ShowWindow", variables: [ ...(args.length === 1 ? [ "", args[0].value ] : [ args[0].value, args[1].value ]), "", "" ], description: context.FormatSourceRefAndDescription(expression[0]) },
						args.catchTimeout && !Number.isNaN(+args.catchTimeout[0].value) ? { timeout: +args.catchTimeout[0].value, catchTimeout: true } : {},
						args.disableInteractionDetection && args.disableInteractionDetection[0].value === 1 ? { disablePause: true } : {},
					));
					return toAppend;
				}, header: "([$title]; $message)", acceptedNamedParameters: [ "catchTimeout", "disableInteractionDetection" ] },
				"Timestamp.MsFromTimestamp": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 1) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires one parameter: ($timestamp)" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "VariableOperation", action: "MsFromTimestamp", variables: [ returnValue.value, args[0].value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "($timestamp): $ms" },
				"Timestamp.Now": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 0) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires no parameters" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "VariableOperation", action: "Now", variables: [ returnValue.value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "(): string" },
				"Timestamp.TimestampFromMs": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 1) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires one parameter: ($ms)" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "VariableOperation", action: "TimestampFromMs", variables: [ returnValue.value, args[0].value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "($ms): $timestamp" },
				"Timestamp.ToLocalTime": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 1) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires one parameter: ($timestamp)" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "VariableOperation", action: "ToLocalTime", variables: [ returnValue.value, args[0].value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "($timestamp): $timestamp" },
				"Timestamp.ToUTCTime": { index: 0, line: 0, column: 0, file: "(internal)", type: "function", value: "", compile: (context, expression, args, returnValue) => {
					const toAppend = { steps: [] };
					if (args.length !== 1) {
						context.errors.push([ context.FormatSourceRefAndDescription(expression[0]), "Function requires one parameter: ($timestamp)" ]);
						return toAppend;
					}
					const variable = context.RequestTempVariable(expression[0]);
					Object.assign(returnValue, { type: "variable", value: variable, toFree: true });
					toAppend.steps.push({ stepType: "VariableOperation", action: "ToUTCTime", variables: [ returnValue.value, args[0].value ], description: context.FormatSourceRefAndDescription(expression[0]) });
					return toAppend;
				}, header: "($timestamp): $timestamp" },
			},
		};
		const startAt = performance.now();
		const [ result, context ] = (() => {
			try {
				return replaceSourceToHTML(src, { startIndex: 0, startLine: 1, startColumn: 0, srcFile: "" }, globalExternalContext);
			} catch (err) {
				errorsTBody.innerHTML = "";
				const tr = errorsTBody.appendChild(document.createElement("tr"));
				tr.appendChild(document.createElement("td")).textContent = JSON.stringify([ "[INTERNAL]", err.message ]);
				console.error(err);
				return [];
			}
		})();
		const compileTime = performance.now() - startAt;
		if (!context) {
			return;
		}
//		context.Compile();
		if (context.CurrentScope().owner !== "root") {
			switch (context.CurrentScope().owner) {
				case "brackets":
					context.errors.push([ "", ") expected" ]);
					break;
				case "accessor":
					context.errors.push([ "", "] expected" ]);
					break;
				case "control":
					context.errors.push([ "", "end expected" ]);
					break;
				default:
					context.errors.push([ "", "Invalid syntax", context.CurrentScope().owner ]);
			}
		}
		Object.entries(context.scopes[0].variables).forEach(([ varKey, varData ]) => {
			// TODO implement tree-shaking with varData.read and varData.written, and readonly ("variableType: Fixed")
			if (!context.flow.variables.find(variable => variable.key === varKey)) {
				context.flow.variables.push({ key: varKey, value: varData.initialValue ?? "", variableType: varData.writable ? "ExecutionTime" : "Fixed" });
			}
		});
		window._lastTree = context;
		errorsTBody.innerHTML = "";
		Object.entries(context.activeTempVariables).filter(([ variable, value ]) => value).forEach(([ variable, value ]) => {
			context.errors.push([ "", "[INTERNAL] Temporary variable not freed", variable, value ]);
		});
		if (context.errors.length) {
			context.errors.forEach(error => {
				const tr = errorsTBody.appendChild(document.createElement("tr"));
				tr.appendChild(document.createElement("td")).textContent = JSON.stringify(error);
			});
			console.error(context.errors);
		}
		sourceCodeEditor.innerHTML = result;
		lineNumbersArea.textContent = result.split("\n").map((e, i) => i + 1).join("\n");
		lineNumbersArea.scrollTop = sourceCodeEditor.scrollTop;
		context.flow.compiler = { src, version: versionElement.textContent };
		outputJsonArea.textContent = JSON.stringify(context.flow, undefined, 2);
		selectedSuggestion = context.suggestions[0] ?? { option: "  ", remove: 0 };
		suggestionsList.innerHTML = context.suggestions.map(suggestion => "<li>" + suggestion.option + "</li>").join("");
		statisticsTable.innerHTML = "";
		Object.entries({
			"compile / errors": compileTime + " ms / " + context.errors.length,
			"steps / variables": context.flow.steps.length + " / " + context.flow.variables.length,
			"size /(minified)": JSON.stringify(context.flow, undefined, 4).length + " B (" + JSON.stringify(context.flow).length + " B)",
			"code": context.flow.compiler.src.length + " B",
			"flow /(minified)": JSON.stringify(Object.assign({}, context.flow, { compiler: undefined }), undefined, 4).length + " B (" + JSON.stringify(Object.assign({}, context.flow, { compiler: undefined })).length + " B)",
		}).forEach(([ key, value ]) => {
			const tr = statisticsTable.appendChild(document.createElement("tr"));
			tr.appendChild(document.createElement("th")).textContent = key;
			tr.appendChild(document.createElement("td")).textContent = value;
		});
	};
	processCode();
	const getAnchorAndFocusPositions = () => {
		const selection = document.getSelection();
		let { anchorNode, anchorOffset, focusNode, focusOffset } = selection;
		if (anchorNode.nodeName === "#text") {
			if (anchorNode.parentElement.hasAttribute("start")) {
				anchorNode = anchorNode.parentElement;
			} else if (anchorNode.previousElementSibling) {
				anchorNode = anchorNode.previousElementSibling;
				anchorOffset += anchorNode.textContent.length;
			} else if (anchorNode.nextElementSibling) {
				anchorNode = anchorNode.nextElementSibling;
				anchorOffset = 0;
			}
		}
		if (focusNode.nodeName === "#text") {
			if (focusNode.parentElement.hasAttribute("start")) {
				focusNode = focusNode.parentElement;
			} else if (focusNode.previousElementSibling) {
				focusNode = focusNode.previousElementSibling;
				focusOffset += focusNode.textContent.length;
			} else if (focusNode.nextElementSibling) {
				focusNode = focusNode.nextElementSibling;
				focusOffset = 0;
			}
		}
		if (anchorNode === sourceCodeEditor) {
			while (anchorOffset > 0 && sourceCodeEditor.childNodes[anchorOffset - 1].nodeName === "#text") {
				anchorOffset--;
			}
			while (anchorOffset < sourceCodeEditor.childNodes.length - 2 && sourceCodeEditor.childNodes[anchorOffset + 1].nodeName === "#text") {
				anchorOffset++;
			}
			anchorNode = sourceCodeEditor.childNodes[anchorOffset];
			anchorOffset = -1;
		}
		if (focusNode === sourceCodeEditor) {
			while (focusOffset > 0 && sourceCodeEditor.childNodes[focusOffset - 1].nodeName === "#text") {
				focusOffset--;
			}
			while (focusOffset < sourceCodeEditor.childNodes.length - 2 && sourceCodeEditor.childNodes[focusOffset + 1].nodeName === "#text") {
				focusOffset++;
			}
			focusNode = sourceCodeEditor.childNodes[focusOffset];
			focusOffset = -1;
		}
		//console.log("old", anchorNode, anchorOffset, focusNode, focusOffset);
		if (!anchorNode?.hasAttribute?.("start") && anchorNode.childNodes[anchorOffset - 1]?.hasAttribute?.("start")) {
			anchorNode = anchorNode.childNodes[anchorOffset - 1];
			anchorOffset = anchorNode.textContent.length;
		}
		if (!focusNode?.hasAttribute?.("start") && focusNode.childNodes[focusOffset - 1]?.hasAttribute?.("start")) {
			focusNode = focusNode.childNodes[focusOffset - 1];
			focusOffset = focusNode.textContent.length;
		}
		const anchorPos = +(anchorNode?.getAttribute?.("start") ?? 0) + anchorOffset;
		const focusPos = +(focusNode?.getAttribute?.("start") ?? 0) + focusOffset;
		return { anchorPos, focusPos };
	};
	const setAnchorAndFocusPositions = ({ anchorPos, focusPos }) => {
		let anchorPosRemaining = anchorPos;
		let focusPosRemaining = focusPos;
		let newAnchorNode = null;
		let newAnchorOffset = 0;
		let newFocusNode = null;
		let newFocusOffset = 0;
		const selection = document.getSelection();
		for (let node of sourceCodeEditor.childNodes) {
			if (anchorPosRemaining >= 0 && node.textContent.length > anchorPosRemaining) {
				newAnchorNode = node;
				newAnchorOffset = anchorPosRemaining;
			}
			anchorPosRemaining -= node.textContent.length;
			if (focusPosRemaining >= 0 && node.textContent.length > focusPosRemaining) {
				newFocusNode = node;
				newFocusOffset = focusPosRemaining;
			}
			focusPosRemaining -= node.textContent.length;
		}
		if (!newAnchorNode) {
			newAnchorNode = sourceCodeEditor.lastChild;
			newAnchorOffset = sourceCodeEditor.lastChild.textContent.length;
		}
		if (!newFocusNode) {
			newFocusNode = sourceCodeEditor.lastChild;
			newFocusOffset = sourceCodeEditor.lastChild.textContent.length;
		}
		if (newAnchorNode && newAnchorNode.nodeName !== "#text" && newAnchorNode.firstChild?.nodeName === "#text") {
			newAnchorNode = newAnchorNode.firstChild;
		}
		if (newFocusNode && newFocusNode.nodeName !== "#text" && newFocusNode.firstChild?.nodeName === "#text") {
			newFocusNode = newFocusNode.firstChild;
		}
		//console.log("new", newAnchorNode, newAnchorOffset, newFocusNode, newFocusOffset);
		selection.setBaseAndExtent(newAnchorNode, newAnchorOffset, newFocusNode, newFocusOffset);
	};
	let savedAnchorPos = null;
	let savedFocusPos = null;
	let toPaste = "";
	sourceCodeEditor.addEventListener("keydown", ev => {
		toPaste = "";
		({ anchorPos: savedAnchorPos, focusPos: savedFocusPos } = getAnchorAndFocusPositions());
		if (ev.code === "Tab") {
			ev.preventDefault();
			const selection = document.getSelection();
			const { anchorNode, anchorOffset, focusNode, focusOffset } = selection;
			if (anchorNode === focusNode && anchorNode.nodeName === "#text") {
				if (anchorOffset > 0) {
					anchorNode.textContent = anchorNode.textContent.substring(0, anchorOffset - selectedSuggestion.remove) + selectedSuggestion.option + anchorNode.textContent.substring(anchorOffset);
					const newAnchorOffset = anchorOffset + selectedSuggestion.option.length - selectedSuggestion.remove;
					selection.setBaseAndExtent(anchorNode, newAnchorOffset, anchorNode, newAnchorOffset);
				} else {
					const newAnchorNode = anchorNode.parentElement.previousElementSibling;
					const textContent = newAnchorNode.textContent;
					newAnchorNode.textContent = textContent.substring(0, textContent.length - selectedSuggestion.remove) + selectedSuggestion.option;
					selection.setBaseAndExtent(anchorNode, 0, anchorNode, 0);
				}
			}
			processCode();
			setAnchorAndFocusPositions({ anchorPos: savedAnchorPos + 2, focusPos: savedFocusPos + 2 });
		} else if (ev.code === "Enter") {
			ev.preventDefault();
			const selection = document.getSelection();
			const { anchorNode, anchorOffset, focusNode, focusOffset } = selection;
			if (anchorNode === focusNode && anchorNode.nodeName === "#text") {
				anchorNode.textContent = anchorNode.textContent.substring(0, anchorOffset) + "\n" + anchorNode.textContent.substring(anchorOffset);
				const newAnchorOffset = anchorOffset + 1;
				selection.setBaseAndExtent(anchorNode, newAnchorOffset, anchorNode, newAnchorOffset);
			}
			processCode();
			setAnchorAndFocusPositions({ anchorPos: savedAnchorPos + 1, focusPos: savedFocusPos + 1 });
		} else if (ev.code === "Backspace") {
			savedAnchorPos = savedFocusPos = savedAnchorPos === savedFocusPos ? savedAnchorPos - 1 : Math.min(savedAnchorPos, savedFocusPos);
		} else if (ev.code === "Delete") {
			savedAnchorPos = savedFocusPos = Math.min(savedAnchorPos, savedFocusPos);
		} else {
			savedAnchorPos = savedFocusPos = Math.min(savedAnchorPos, savedFocusPos) + 1;
		}
	});
	/* TODO
	const history = { list: [ { type: "set", value: sourceCodeEditor.textContent, anchorPos: sourceCodeEditor.textContent.length, focusPos: sourceCodeEditor.textContent.length } ], position: 0 };
	window._history = history;
	let preventDetection = false;
	sourceCodeEditor.addEventListener("keydown", ev => {
		if (!ev.altKey && ev.ctrlKey && !ev.metaKey && !ev.shiftKey && ev.code === "KeyZ" && history.position > 0) {
			const status = history.list[--history.position];
			if (status.type === "set") {
				preventDetection = true;
				sourceCodeEditor.textContent = status.value;
				processCode();
				setAnchorAndFocusPositions({ anchorPos: status.anchorPos, focusPos: status.focusPos });
			}
		}
		if ((!ev.altKey && ev.ctrlKey && !ev.metaKey && !ev.shiftKey && ev.code === "KeyY" || !ev.altKey && ev.ctrlKey && !ev.metaKey && ev.shiftKey && ev.code === "KeyZ") && history.position + 1 < history.list.length) {
			const status = history.list[++history.position];
			if (status.type === "set") {
				preventDetection = true;
				const { anchorPos, focusPos } = getAnchorAndFocusPositions();
				sourceCodeEditor.textContent = status.value;
				processCode();
				setAnchorAndFocusPositions({ anchorPos: status.anchorPos, focusPos: status.focusPos });
			}
		}
	});
	*/
	sourceCodeEditor.addEventListener("paste", ev => {
		toPaste = ev.clipboardData.getData("text/plain");
		// ev.preventDefault();
	});
	sourceCodeEditor.addEventListener("input", ev => {
		if (ev.isComposing) {
			return;
		}
		/* TODO
		if (preventDetection) {
			ev.stopImmediatePropagation();
			ev.stopPropagation();
			return;
		}
		*/
		// TODO history.list.splice(++history.position, Number.MAX_SAFE_INTEGER, { type: "set", value: sourceCodeEditor.textContent, anchorPos, focusPos });
		//console.log("mid", anchorNode, anchorOffset, focusNode, focusOffset, anchorPos, focusPos);
		const data = ev.dataTransfer?.getData("text/plain");
		if (data?.length) {
			savedAnchorPos += data.length - 1;
			savedFocusPos += data.length - 1;
		}
		/* TODO paste
		if (toPaste) {
			console.log(toPaste);
		}
		*/
		processCode();
		setAnchorAndFocusPositions({ anchorPos: savedAnchorPos, focusPos: savedFocusPos });
		const selection = document.getSelection();
		if (selection.anchorNode && selection.focusNode) {
			const anchorNode = selection.anchorNode.nodeName === "#text" ? selection.anchorNode.parentElement : selection.anchorNode;
			const focusNode = selection.focusNode.nodeName === "#text" ? selection.focusNode.parentElement : selection.focusNode;
			const selectedNode = (anchorNode.compareDocumentPosition(focusNode) > 0 ? anchorNode : focusNode);
			const nodeForBounds = selectedNode.previousElementSibling ?? selectedNode;
			const bounds = nodeForBounds.getBoundingClientRect();
			window._showBounds ??= false;
			if (window._showBounds) {
				const div = document.createElement("div");
				Object.assign(div.style, { position: "fixed", top: bounds.top + "px", left: bounds.left + "px", width: bounds.width + "px", height: bounds.height + "px", border: "2px solid lime", pointerEvents: "none" });
				document.body.appendChild(div);
				console.log(selectedNode);
				setTimeout(() => div.remove(), 500);
			}
			if (bounds.top > window.innerHeight / 2) {
				Object.assign(suggestionsList.style, { position: "fixed", top: bounds.top - suggestionsList.offsetHeight + "px", left: bounds.left + "px" });
			} else {
				Object.assign(suggestionsList.style, { position: "fixed", top: bounds.bottom + "px", bottom: "", left: bounds.left + "px" });
			}
		}
	});
	sourceCodeEditor.addEventListener("scroll", ev => {
		lineNumbersArea.scrollTop = sourceCodeEditor.scrollTop;
	});
	buttonPublish.addEventListener("click", ev => {
		diMaFetch(".", { method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify({ command: "save", "argv": [ JSON.parse(outputJsonArea.textContent) ] }) }).then(response => {
			if (response.headers.get("location")) {
				location.href = response.headers.get("location");
			} else if (response.ok) {
				buttonPublish.animate([
					{ backgroundColor: window.getComputedStyle(buttonPublish).backgroundColor },
					{ backgroundColor: "hsl(120deg 100% 50%)", offset: 0.1 },
					{ backgroundColor: "hsl(120deg 100% 50%)", offset: 0.9 },
					{ backgroundColor: window.getComputedStyle(buttonPublish).backgroundColor },
				], { duration: 5000 });
			} else {
				buttonPublish.animate([
					{ backgroundColor: window.getComputedStyle(buttonPublish).backgroundColor },
					{ backgroundColor: "hsl(0deg 100% 50%)", offset: 0.1 },
					{ backgroundColor: "hsl(0deg 100% 50%)", offset: 0.9 },
					{ backgroundColor: window.getComputedStyle(buttonPublish).backgroundColor },
				], { duration: 5000 });
			}
		});
	});
	buttonLoad.addEventListener("click", ev => {
		diMaFetch(".?flowList=1").then(res => res.json()).then(response => {
			if (!response.ok) return;
			const dialog = document.body.appendChild(document.createElement("dialog"));
			dialog.setAttribute("id", "load");
			Object.assign(dialog.appendChild(document.createElement("button")), { textContent: "Close" }).addEventListener("click", ev => {
				dialog.close();
			});
			const list = dialog.appendChild(document.createElement("div"));
			response.list.forEach(flow => {
				const node = list.appendChild(document.createElement("div"));
				node.addEventListener("click", ev => {
					if (ev.ctrlKey) {
						alert("Open in new tab not supported at the moment");
					} else {
						diMaFetch(".?flow=" + flow._id).then(res => res.json()).then(json => {
							sourceCodeEditor.innerHTML = json.src;
							processCode();
							//const url = new URL(location.href);
							//url.searchParams.set("flow", flow._id);
							//history.pushState({}, "", url.href);
						});
					}
				});
				if (!flow.status) {
					node.classList.add("disabled");
				}
				Object.assign(node.appendChild(document.createElement("span")), { textContent: flow.name }).classList.add("name");
				Object.assign(node.appendChild(document.createElement("span")), { textContent: flow.description }).classList.add("description");
				Object.assign(node.appendChild(document.createElement("span")), { textContent: flow.updateAt }).classList.add("modification");
			});
			dialog.showModal();
		});
	});
	sourceCodeEditor.addEventListener("mouseout", ev => {
		hintDiv.textContent = "";
		hintDiv.style.minHeight = "20px";
	});
	sourceCodeEditor.addEventListener("mouseover", ev => {
		try {
			if (ev.target.dataset.hint) {
				hintDiv.textContent = JSON.stringify(JSON.parse(ev.target.dataset.hint), undefined, 2);
			}
		} catch (ignore) {}
	});
    }
}
</script>
<style>
body:has(>#body) {
	margin: 0;
}
#body, #body *, dialog#load, dialog#load * {
	/*all: revert;*/
	box-sizing: border-box;
	margin: 0;
}
#body {
	all: revert;
	height: 100%;
	padding: 10px;
	display: flex;
	flex-direction: column;
	background: #000;
	color: #fff;
}
#code-div {
	height: 0;
	flex-grow: 1;
	display: flex;
}
#editor-div {
	width: 0;
	flex-grow: 1;
	display: flex;
	gap: 8px;
}
#line-numbers {
	text-align: right;
	overflow-y: hidden;
}
#source-code {
	width: 0;
	flex-grow: 1;
	height: 100%;
	overflow-y: scroll;
	padding-left: 8px;
}
#output-and-errors-div {
	width: 0;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}
#errors-div {
	max-height: 100px;
	overflow-y: auto;
	table {
		color: #f00;
	}
}
#output-json {
	height: 0;
	flex-grow: 1;
	overflow-y: scroll;
	user-select: all;
}
#editor-div, #output-and-errors-div {
	transition: flex-grow 0.04s;
}
#editor-div:focus-within, #output-and-errors-div:focus-within {
	flex-grow: 4;
}
#suggestions-list {
	max-height: 5rem;
	overflow-y: scroll;
	background: #222;
}
#suggestions-list:not(:has(li)) {
	display: none;
}
#right-menu {
	display: flex;
	flex-direction: column;
	gap: 4px;
}
#changelog-popover {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 90%;
	max-height: 90%;
	overflow-y: scroll;
	background: #333;
	color: #eee;
	padding: 20px;
}
dialog#load {
	background-color: #003;
	color: #8ff;
	width: max(500px, 50%);
	height: 80%;
	margin: auto;
	& > div {
		display: grid;
		grid-template-columns: 1fr auto;
		& > div {
			grid-column: 1 / -1;
			display: grid;
			grid-template-columns: subgrid;
			border: 1px solid color-mix(in hsl, currentColor, #003);
			padding: 4px;
			cursor: pointer;
			transition: background-color 0.2s;
			&:hover {
				background-color: #006;
				color: #dff;
			}
			&.disabled {
				background-color: #448;
				filter: grayscale(80%);
			}
			& > .name {
				grid-area: 1 / 1 / 2 / 2;
			}
			& > .description {
				grid-area: 2 / 1 / 3 / 3;
				color: color-mix(in hsl, currentColor, #0f0);
				font-style: italic;
			}
			& > .modification {
				grid-area: 1 / 2 / 2 / 3;
			}
		}
	}
	&::backdrop {
		background-color: #000a;
	}
}
</style>
